/**
 * Copyright © 2024 Adnuntius AS.
 */
import angular from 'angular';
import _ from 'lodash';
import translate from 'angular-translate';
import {ALL_COUNTRIES} from "../../common/directives/country-select/countries";

const MODULE_NAME = 'site-country-target-summary-directive';

angular.module(MODULE_NAME, [translate])

  .directive('adnTargetingSiteCountryTargetSummary', function() {
    return {
      restrict: 'A',
      template: '<span ng-repeat="siteCountry in siteCountries">{{siteCountry.name}}<span ng-if="!$last">, </span></span>',
      scope: {
        targets: '=adnTargetingSiteCountryTargetSummary'
      },
      link: function(scope) {
        scope.$watchCollection('targets', function(targets) {
          scope.target = targets;
        });
        scope.$watchCollection('target', function(target) {
          scope.siteCountries = _.map(target.countries, function(countryCode) {
            return _.find(ALL_COUNTRIES, function(country) {
              return country.code === countryCode;
            }, {
              code: countryCode,
              name: countryCode
            });
          });
        });
      }
    };
  });

export default MODULE_NAME;