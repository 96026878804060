/**
 * Copyright © 2024 Adnuntius AS.
 */
import angular from 'angular';
import translate from 'angular-translate';
import uiBootstrap from 'angular-ui-bootstrap';

import localNetworkProfile from '../../../components/session/local-network-profile';
import * as _ from "lodash";
import * as moment from "moment/moment";
import {progressBarCalculator} from "./progress-bar-percentage-component";

const MODULE_NAME = "velocity-comp";

angular.module(MODULE_NAME, [translate, uiBootstrap, localNetworkProfile])

  .component('adnVelocity', {
    template: `
      <div>
        <div style="margin-bottom: 3px; text" class="{{ctrl.classStyle}}" ng-if="(ctrl.view === 'FULL' || ctrl.view === 'SLIM') && !(ctrl.translation === 'NOT_APPLICABLE' && ctrl.hideNotApplicable)">
          <div class="label {{ctrl.labelClass}}">{{ "lineItem.delivery." + ctrl.translation | translate  : {percentage: ctrl.outputPercentage} }}</div>
        </div>
        <div ng-repeat="loop in ctrl.progressPercentageLoopNums track by $index" ng-if="ctrl.view === 'FULL' || ctrl.view === 'CHART'">
          <div style="text-align: left; clear: left; min-width: 70px; position: relative;" class="small">
            <span class="progress-bar" role="progressbar" style="width: {{loop || 0}}%; background-color: #a9ceef;">&nbsp;</span>
            <span ng-if="$index === 0" style="position: absolute; left: 2px; top: 0"><small><span ng-if="ctrl.progressPercentage > ctrl.displayProgressPercentage">&gt;</span>{{ ::ctrl.displayProgressPercentage / 100 | percentage: 0: true }} delivery</small></span>
          </div>
        </div>
        <div ng-repeat="loop in ctrl.basePercentageLoopNums track by $index" ng-if="ctrl.view === 'FULL' || ctrl.view === 'CHART'">
          <div style="text-align: left; clear: left; min-width: 70px; position: relative;" class="small">
            <span class="progress-bar" role="progressbar" style="width: {{loop || 0}}%; background-color: #daebf8;">&nbsp;</span>
            <span ng-if="$index === 0" style="position: absolute; left: 2px; top: 0"><small>{{ ::ctrl.basePercentage / 100 | percentage: 0: true }} time</small></span>
          </div>
        </div>
      </div>
    `,
    controllerAs: 'ctrl',
    bindings: {
      hideNotApplicable: '<',
      progressPercentage: '<',
      basePercentage: '<',
      maximum: '<',
      endDate: '<',
      view: '@',
      classStyle: '@',
      velocity: '<'
    },
    controller: function() {
      const ctrl = this;

      ctrl.$onInit = function() {
        if (!_.isFinite(ctrl.velocity)) {
          ctrl.labelClass = 'label-default';
          ctrl.translation = "NOT_APPLICABLE";
          return;
        }
        if (!ctrl.endDate || !_.isDate(moment(ctrl.endDate).toDate())) {
          ctrl.labelClass = 'label-default';
          ctrl.translation = "NO_END_DATE";
          return;
        }

        ctrl.classStyle = ctrl.classStyle === 'none' ? "" : "small";
        let maximum = ctrl.maximum || 1000;

        ctrl.progressPercentage = ctrl.progressPercentage || 0;
        ctrl.displayProgressPercentage = Math.min(maximum, ctrl.progressPercentage);

        ctrl.progressPercentageLoopNums = progressBarCalculator(ctrl.displayProgressPercentage);
        ctrl.basePercentageLoopNums = progressBarCalculator(ctrl.basePercentage);

        const rawPercentage = (Math.pow(1.25, ctrl.velocity) - 1) * 100;
        ctrl.roundedPercentage = Math.sign(rawPercentage) * Math.ceil(Math.abs(rawPercentage) / 5) * 5;

        ctrl.outputPercentage = ctrl.roundedPercentage.toString();

        if (ctrl.roundedPercentage > 20) {
          ctrl.labelClass = 'label-warning';
          ctrl.translation = 'OVER';
        } else if (ctrl.roundedPercentage < -20) {
          ctrl.labelClass = 'label-danger';
          ctrl.translation = 'UNDER';
        } else if (ctrl.roundedPercentage === 0) {
          ctrl.labelClass = 'label-primary';
          ctrl.translation = 'HEALTHY_ON_TARGET';
        } else {
          ctrl.labelClass = 'label-primary';
          ctrl.translation = 'HEALTHY';
          ctrl.outputPercentage = ctrl.roundedPercentage > 0 ? ("+" + ctrl.roundedPercentage.toString()) : ctrl.roundedPercentage.toString();
        }
        if (ctrl.roundedPercentage > 50) {
          ctrl.labelClass = 'label-danger';
        }
      };
    }
  });

export default MODULE_NAME;