/**
 * Copyright © 2024 Adnuntius AS.
 */
import angular from 'angular';
import _ from 'lodash';
import translate from 'angular-translate';

const MODULE_NAME = 'day-parting-targeting-summary-directive';

angular.module(MODULE_NAME, [translate])

  .directive('adnTargetingDayPartingSummary', function() {
    return {
      restrict: 'A',
      template: '<span class="commaSeparated"><span ng-repeat="(day, hours) in data" class="commaSeparate">{{"label.days." + day | translate}}<span ng-if="hours !== 24"> ({{hours}} hours)</span></span></span>',
      scope: {
        targets: '=adnTargetingDayPartingSummary'
      },
      link: function(scope) {
        scope.data = [];
        scope.$watchCollection('targets', function(targets) {
          scope.target = targets || {};
        });
        scope.$watchCollection('target', function(target) {
          scope.data = {};
          _.forEach(['MONDAY', 'TUESDAY', 'WEDNESDAY', 'THURSDAY', 'FRIDAY', 'SATURDAY', 'SUNDAY'], function(day) {
            let theHours = _.filter(target, function(entryObj) {
              return _.indexOf(entryObj.daysOfWeek, day) > -1;
            });
            let totalHours = _.flatten(_.map(theHours, 'hoursOfDay')).length;
            if (totalHours > 0) {
              scope.data[day] = totalHours;
            }

          });
        });
      }
    };
  });

export default MODULE_NAME;