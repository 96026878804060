/**
 * Copyright © 2024 Adnuntius AS.
 */
import angular from 'angular';
import _ from 'lodash';
import uiTranslate from 'angular-translate';

import template from './creative-view.html';

import resources from '../../../components/api/resources/resources';
import previewComponent from './preview-component';
import promiseSpinner from '../../common/directives/html/promise-spinner-component';

const MODULE_NAME = 'creative-preview-via-layout-component';

angular.module(MODULE_NAME, [
  uiTranslate,
  resources,
  promiseSpinner,
  previewComponent
])

  .component('adnCreativePreviewViaLayout', {
    bindings: {
      previewData: '<',
      creative: '<',
      creativePreviewTrigger: '<previewTrigger',
      maxWidth: '@',
      maxHeight: '@'
    },
    template: template,
    controllerAs: 'ctrl',
    controller: function($q, Preview) {
      const ctrl = this;
      ctrl.previewTrigger = {};
      ctrl.resolved = false;

      ctrl.$onInit = function() {

        let preview = function(params) {
          ctrl.resolved = false;

          // Bail out on the following set of conditions
          if (!params || (!params.layoutId && !params.renderTemplate && !params.creative)) {
            ctrl.resolved = true;
            return;
          }

          const layoutType = _.get(params.creative, ['layout', 'layoutType']) || '';
          const ogLayoutType = _.get(params.creative, ['layout', 'ogLayoutType']) || '';
          const promises = [];

          let layoutParams = {};
          const totalAssets = _.get(params, ['creative', 'assets'], []).concat(_.get(params, ['creative', 'libraryCreativeAssets'], []));
          _.forEach(params.creative.layoutParameters, function(v, k) {
            if (v && v.id && !v.content) {
              const assetId = v.id;
              layoutParams[k] = _.pick((_.find(totalAssets, ['id', assetId]) || {}), ['cdnId', 'previewCdnId', 'transcode', 'width', 'height', 'content', 'duration', 'bps']);
            } else {
              layoutParams[k] = v;
            }
          });

          const apiPreviewData = {
            creativeWidth: params.creative.width || params.creative.height || 300,
            creativeHeight: params.creative.height || params.creative.width || 300,
            layoutParameters: layoutParams,
            video: params.video
          };
          if (params.layoutId) {
            apiPreviewData.layoutId = params.layoutId;
          }
          if (_.get(params.creative, ['layout', 'id'])) {
            apiPreviewData.layoutId = params.creative.layout.id;
          }
          if (params.renderTemplate) {
            apiPreviewData.renderTemplate = params.renderTemplate;
          }

          if (!apiPreviewData.layoutParameters || _.isEmpty(apiPreviewData.layoutParameters)) {
            $q.resolve("");
          } else {
            promises.push(new Preview(apiPreviewData).$save().then(function(data) {
              return data.document;
            }));
          }

          $q.all(promises).then(function(results) {
            let creativeContent = results[0] || "\x3Chtml>\x3Cbody>\x3C/body>\x3C/html>";

            let previewData = {
              pageTitle: params.renderTemplate ? "Full-Screen Layout Preview" : "Full-Screen Creative Preview",
              creativeContent: creativeContent,
              creativeWidth: params.creative.width || params.creative.height || 300,
              creativeHeight: params.creative.height || params.creative.width || 300,
              dimensionType: params.creative.dimensionType,
              maxWidth: ctrl.maxWidth,
              maxHeight: ctrl.maxHeight,
              center: true
            };

            ctrl.previewTrigger.previewInline(previewData, apiPreviewData, ogLayoutType ? ogLayoutType === 'VAST' : layoutType === 'VIDEO' || layoutType === 'AUDIO');
            ctrl.resolved = true;
          });
        };
        preview(ctrl.previewData);
        ctrl.creativePreviewTrigger = ctrl.creativePreviewTrigger || {};
        ctrl.creativePreviewTrigger.previewCreative = function(previewData) {
          preview(previewData);
        };
      };
    }
  });

export default MODULE_NAME;