/**
 * Copyright © 2024 Adnuntius AS.
 */
import angular from 'angular';
import translate from 'angular-translate';
import uiBootstrap from 'angular-ui-bootstrap';

import template from './publisher-info-component.html';
import {OBJECT_TYPE} from "../constants/object-type";
import floorPriceComponent from "./floor-price-component";

const MODULE_NAME = "publisher-info-comp";

angular.module(MODULE_NAME, [translate, uiBootstrap, floorPriceComponent])

  .component('adnPublisherInfo', {
    template: template,
    controllerAs: 'ctrl',
    bindings: {
      id: '<',
      infoReset: '<',
      objTypeRef: '@'
    },
    controller: function(PublisherInfo, $log) {
      const ctrl = this;

      ctrl.$onInit = function() {
        const obj = OBJECT_TYPE[ctrl.objTypeRef];
        if (!obj) {
          $log.warn("need a good object reference and this is not it: " + ctrl.objTypeRef);
          return;
        }

        function pubInfoReset() {
          ctrl.resolved = true;
          PublisherInfo[obj.apiParam](ctrl.id).then(function(data) {
            ctrl.resolved = false;
            ctrl.publisherInfo = data;
          });
        }

        if (ctrl.infoReset) {
          ctrl.infoReset.info = function() {
            pubInfoReset();
          };
        } else {
          pubInfoReset();
        }
      };
    }
  });

export default MODULE_NAME;