/**
 * Copyright © 2024 Adnuntius AS.
 */
import angular from 'angular';
import translate from 'angular-translate';
import {ADN_TARGETING_TYPES} from "../targeting-constants";
import {TargetingHelper} from "../targeting-helper";

const MODULE_NAME = 'named-location-targeting-summary-directive';

angular.module(MODULE_NAME, [translate])

  .directive('adnTargetingNamedLocationSummary', function() {
    return {
      restrict: 'A',
      template: '<strong ng-if="targets.negated">NOT:&nbsp;</strong>{{locations}}',
      scope: {
        targets: '=adnTargetingNamedLocationSummary'
      },
      link: function(scope) {
        scope.data = [];
        scope.$watchCollection('targets', function(targets) {
          scope.targets = targets || {};
        });
        scope.$watchCollection('targets', function(targets) {
          scope.locations = TargetingHelper.getSummary(targets, ADN_TARGETING_TYPES.namedLocation);
        });
      }
    };
  });

export default MODULE_NAME;