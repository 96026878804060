/**
 * Copyright © 2024 Adnuntius AS.
 */
import angular from 'angular';
import _ from 'lodash';
import uiBootstrap from 'angular-ui-bootstrap';
import translate from 'angular-translate';

import template from './keyword-targeting.html';

import resources from '../../../components/api/resources/resources';
import promiseSpinner from '../../common/directives/html/promise-spinner-component';
import {TargetingHelper} from "../targeting-helper";
import {ADN_TARGETING_TYPES} from "../targeting-constants";
import moment from "moment";
import keywordTargetingSearchResults from "./keyword-targeting-search-results.html";

const MODULE_NAME = 'keyword-targeting-directive';

angular.module(MODULE_NAME, [translate, uiBootstrap, resources, promiseSpinner])

  .directive('adnKeywordTargeting', function(keywordSearchResource, keywordProfileSearchResource, $templateCache, LocalNetworkProfile) {
    return {
      restrict: 'A',
      require: '^^adnTabset',
      template: template,
      scope: {
        model: '=adnKeywordTargeting',
        underModel: '=',
        isLockedDown: '<',
        overridableTargeting: '<',
        mandatory: '<',
        mode: '@'
      },
      link: {
        post: function(scope, element, attrs, tabset) {
          scope.targetType = ADN_TARGETING_TYPES.keyword;
          scope.inputted = scope.inputted || {};
          scope.inputted2 = scope.inputted2 || {};

          scope.isMarketplace = LocalNetworkProfile.isPureMarketplacePlatform();
          $templateCache.put("keywordTargeting.html", keywordTargetingSearchResults);

          let complexInit = function() {
            TargetingHelper.complexInit(scope);
          };
          let simpleInit = function() {
            TargetingHelper.simpleInit(scope, "keywords");
          };
          let initInit = function() {
            scope.template.complexInput = init();
          };
          let init = function() {
            complexInit();
            simpleInit();
            return TargetingHelper.isComplex(scope.model, ADN_TARGETING_TYPES.keyword.id);
          };
          scope.template = {
            loaded: true
          };
          initInit();
          scope.loaded = true;

          scope.resolved = true;
          scope.search = function(newSearch, mode) {
            scope.searchMeta.resolved = false;
            scope.searchMeta.error = false;
            if (newSearch) {
              scope.searchMeta.currentPage = 1;
            }
            scope.searchMeta.mode = mode || scope.searchMeta.mode;

            if (scope.searchMeta.mode === 'KEYWORDS') {
              const params = {
                q: scope.search.term || '',
                pageSize: scope.searchMeta.pageSize,
                page: scope.searchMeta.currentPage - 1
              };
              if (!params.q) {
                params.orderBy = 'FREQUENCY';
                params.orderByDirection = 'DESCENDING';
              }
              keywordSearchResource.query(params).then(function(page) {
                scope.list = page;

                if (!scope.template.complexInput) {
                  let trimmedValues = TargetingHelper.trimValues(scope);

                  _.forEach(_.get(scope.list, ['searchResults'], []), function(a) {
                    a.selected = _.indexOf(trimmedValues, a.keyword) > -1;
                  });
                }
                scope.searchMeta.resolved = true;
              });
              return;
            }

            const articleSearchParams = {model: []};
            if (scope.searchMeta.keywordChoice === 'BELOW') {
              articleSearchParams.inputted = _.cloneDeep(scope.inputted2);
            } else {
              articleSearchParams.inputted = _.cloneDeep(scope.inputted);
            }
            scope.resultsInfo = {};
            scope.showingNots = false;
            if (scope.template.complexInput) {
              TargetingHelper.assignComplexValuesToNewModel(articleSearchParams, 'keywords', 'notKeywords');
            } else {
              if (_.get(articleSearchParams, ['inputted', 'value'], "").length === 0 && _.get(articleSearchParams, ['inputted', 'valueAll'], "").length === 0 && _.get(articleSearchParams, ['inputted', 'valueNotAny'], "").length > 0) {
                articleSearchParams.inputted.value = articleSearchParams.inputted.valueNotAny;
                articleSearchParams.inputted.valueNotAny = "";
                scope.resultsInfo.showingNots = true;
              }
              scope.resultsInfo = _.merge(scope.resultsInfo, TargetingHelper.assignAllTheValuesToPipedModel(articleSearchParams, ADN_TARGETING_TYPES.keyword.apiProp, 1000));
            }
            if (_.get(articleSearchParams, ['inputted', 'value'], "").length === 0 && _.get(articleSearchParams, ['inputted', 'valueAll'], "").length === 0 && _.get(articleSearchParams, ['inputted', 'valueNotAny'], "").length === 0) {
              scope.list = {results: []};
              scope.searchMeta.error = 'Specify keywords to see what articles currently match';
              scope.searchMeta.resolved = true;
              return;
            }
            keywordProfileSearchResource.post(articleSearchParams.model[0], scope.searchMeta).then(function(page) {
              scope.list = page;
              _.forEach(scope.list.results, function(profile) {
                profile.count = 0;
                profile.matched = profile.matched || [];
                profile.count = profile.matched.length * 100 + profile.keywords.length;
                profile.site = profile.url.split('/')[0];
                if (profile.publishedTime) {
                  profile.publishedTime = moment(profile.publishedTime).toDate().toDateString();
                }
                profile.title = profile.title || profile.url;
                profile.image = profile.image || '';
                _.remove(profile.keywords, function(keyword) {
                  return profile.matched.includes(keyword);
                });
                profile.all = profile.matched.concat(profile.keywords);
              });
              scope.searchMeta.resolved = true;
            });
          };

          scope.searchMeta = {
            pageSize: 100,
            currentPage: 1,
            mode: 'ARTICLES',
            keywordChoice: 'ABOVE',
            changePage: function() {
              scope.search();
            },
            resolved: true
          };

          scope.refine = function() {
            if (scope.searchMeta.keywordChoice === 'BELOW') {
              scope.inputted2 = _.cloneDeep(scope.inputted);
            }
          };

          scope.manageKeyword = function(keyword, type, remove, index) {
            let param = 'value';
            if (type === 'ALL') {
              param = 'valueAll';
            } else if (type === 'NOT') {
              param = 'valueNotAny';
            } else if (type === 'ANY_AND') {
              param = 'valueAnded';
            }
            let inputtedElement = _.isNumber(index) ? scope.inputted[param][index] : scope.inputted[param];
            const arr = _.filter(_.map((_.get(inputtedElement, ['value'], inputtedElement) || "").split(","), function(entry) {
              return _.trim(entry);
            }), function(entry) {
              return entry && (!remove || entry !== keyword);
            });
            if (!remove) {
              arr.push(keyword);
            }
            if (_.isNumber(index)) {
              scope.inputted[param][index].value = arr.join(", ");
            } else {
              scope.inputted[param] = arr.join(", ");
            }
          };

          scope.select = function(searchResult, param) {
            searchResult.selected = true;

            let trimmedValues = TargetingHelper.trimValues(scope);
            trimmedValues[param].push(searchResult.keyword);
            TargetingHelper.assignToInput(scope, trimmedValues);
          };

          scope.addOr = function(searchResult) {
            scope.addEntry(searchResult.keyword);
          };
          scope.addAnd = function(searchResult) {
            scope.addRow(null, searchResult.keyword);
          };
          scope.addAndNot = function(searchResult) {
            scope.addNotRow(null, searchResult.keyword);
          };

          scope.addKeywordAnd = function() {
            scope.inputted.valueAnded = scope.inputted.valueAnded || [];
            scope.inputted.valueAnded.push({value: ""});
          };

          scope.addKeywordAnd2 = function() {
            scope.inputted2.valueAnded = scope.inputted2.valueAnded || [];
            scope.inputted2.valueAnded.push({value: ""});
          };

          scope.addEntry = function(entry) {
            TargetingHelper.checkAndCreateBaseInput(scope);
            const input = entry || '';
            scope.inputs.push({keywords: [{k: input, not: false}], notKeywords: []});
          };

          scope.addRow = function(index, entry) {
            TargetingHelper.checkAndCreateBaseInput(scope);
            const input = entry || '';
            const theIndex = _.isFinite(index) ? index : scope.inputs.length - 1;
            scope.inputs[theIndex].keywords.push({k: input, not: false});
          };

          scope.addNotRow = function(index, entry) {
            TargetingHelper.checkAndCreateBaseInput(scope);
            const input = entry || '';
            const theIndex = _.isFinite(index) ? index : scope.inputs.length - 1;
            scope.inputs[theIndex].notKeywords.push({k: input, not: false});
          };

          scope.negateRow = function(parentIndex, index, isNot) {
            const param = isNot ? 'notKeywords' : 'keywords';
            scope.inputs[parentIndex][param][index].not = !scope.inputs[parentIndex][param][index].not;
          };

          scope.removeRow = function(parentIndex, index, isNot) {
            scope.inputs[parentIndex][isNot ? 'notKeywords' : 'keywords'].splice(index, 1);
            if (scope.inputs[parentIndex].keywords.length === 0 && scope.inputs[parentIndex].notKeywords.length === 0) {
              scope.inputs.splice(parentIndex, 1);
            }
          };

          let prepareForSave = function() {
            scope.model = scope.model || [];
            scope.model.length = 0;
            if (scope.template.complexInput) {
              TargetingHelper.assignComplexValuesToNewModel(scope, 'keywords', 'notKeywords');
              init();
            } else {
              TargetingHelper.assignAllTheValuesToPipedModel(scope, ADN_TARGETING_TYPES.keyword.apiProp);
              init();
            }
          };

          scope.doAdRequestSearch = function() {
            scope.search(true, 'KEYWORDS');
          };

          scope.hook = {};
          scope.hook.getTemplateData = function() {
            prepareForSave();

            return {
              vm: angular.copy(scope.vm),
              model: angular.copy(scope.model),
              constant: ADN_TARGETING_TYPES.keyword,
              summary: TargetingHelper.isComplex(scope.model, ADN_TARGETING_TYPES.keyword.id) ? (TargetingHelper.getSizeOfTargetingTypes(scope.model, ADN_TARGETING_TYPES.keyword.targets) + " keywords with complex logic") : scope.inputted.value
            };
          };

          const copyTemplateFunc = function(template) {
            scope.model = angular.copy(template.data.model);
            initInit();
          };
          scope.hook.copyTemplate = copyTemplateFunc;

          tabset.register('adnKeywordTargeting', () => {
            if (!scope.isMarketplace) {
              scope.search(true, 'KEYWORDS');
            }
          }, copyTemplateFunc);
          tabset.callBeforeSubmit(prepareForSave);
          tabset.registerAllTabs(prepareForSave);
        }
      }
    };
  });

export default MODULE_NAME;