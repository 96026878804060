/**
 * Copyright © 2024 Adnuntius AS.
 */
import angular from 'angular';
import _ from 'lodash';
import uiBootstrap from 'angular-ui-bootstrap';
import translate from 'angular-translate';
import checklistModel from 'checklist-model';

import template from './third-party-audience-targeting.html';

import resources from '../../../components/api/resources/resources';
import promiseSpinner from '../../common/directives/html/promise-spinner-component';
import {ADN_TARGETING_TYPES} from "../targeting-constants";
import {TargetingHelper} from "../targeting-helper";

const MODULE_NAME = 'third-party-audience-targeting-directive';

angular.module(MODULE_NAME, [translate, uiBootstrap, checklistModel, resources, promiseSpinner])

  .directive('adnThirdPartyAudienceTargeting', function(ThirdPartyAudience, $log, LocalNetworkProfile) {
    return {
      restrict: 'A',
      require: '^^adnTabset',
      template: template,
      scope: {
        model: '=adnThirdPartyAudienceTargeting',
        underModel: '=',
        isLockedDown: '<',
        overridableTargeting: '<',
        mandatory: '<',
        modelLineItem: '<',
        mode: '@'
      },
      link: function(scope, element, attrs, tabset) {
        scope.model = scope.model || [];
        scope.targetType = ADN_TARGETING_TYPES.thirdPartyAudience;

        let initHasNothing = true;
        scope.manualClose = false;
        scope.fee = _.get(LocalNetworkProfile.getAudienceFees(), 'NORDIC_DATA_RESOURCES');
        const init = function() {
          if (!scope.inputs) {
            scope.inputs = _.cloneDeep(scope.model);
          }
          initHasNothing = _.get(scope.modelLineItem, 'enabledThirdPartyProviders', []).length === 0;
          scope.disableButtons = initHasNothing && (scope.modelLineItem.executionState === 'RUNNING' || scope.modelLineItem.executionState === 'ENDED');
          return TargetingHelper.isComplexThirdPartyAudience(scope.model);
        };
        init();

        scope.template = {
          loaded: true
        };
        scope.template.complexInput = init();
        scope.loaded = true;

        scope.addEntry = function (entry) {
          const us = angular.copy(entry);
          scope.inputs.push({thirdPartyAudiences: [us], notThirdPartyAudiences: []});
        };

        scope.addNotEntry = function (entry) {
          const us = angular.copy(entry);
          scope.inputs.push({thirdPartyAudiences: [], notThirdPartyAudiences: [us]});
        };

        scope.select = function (audience) {
          scope.showFeeInfo = initHasNothing;
          scope.addEntry(audience);
          audience.selected = true;
        };

        scope.selectOrNot = function (audience) {
          scope.showFeeInfo = initHasNothing;
          scope.addNotEntry(audience);
          audience.selected = true;
        };

        scope.removeFromTarget = function(audience) {
          audience = audience || {};
          audience.selected = false;

          let found = false;
          _.forEach(scope.inputs, function(input) {
            ["thirdPartyAudiences"].forEach(field => {
              input[field] = _.filter(input[field], function(aud) {
                if (!found && ((!audience.id && (!aud || !aud.id)) || _.get(aud, 'id') === _.get(audience, 'id'))) {
                  found = true;
                  return false;
                }
                return true;
              });
            });
          });
          scope.inputs = _.filter(scope.inputs, function(input) {
            return input.thirdPartyAudiences.length > 0;
          });
          (_.find(_.get(scope.list, ['searchResults'], []), ["id", audience.id]) || {}).selected = false;
        };

        scope.addToRow = function (index, entry) {
          const theIndex = _.isFinite(index) ? index : scope.inputs.length - 1;
          scope.inputs[theIndex].thirdPartyAudiences.push(entry || {});
        };

        scope.addNotToRow = function (index, entry) {
          const theIndex = _.isFinite(index) ? index : scope.inputs.length - 1;
          scope.inputs[theIndex].notThirdPartyAudiences = scope.inputs[theIndex].notThirdPartyAudiences || [];
          scope.inputs[theIndex].notThirdPartyAudiences.push(entry || {});
        };

        scope.removeFromRow = function (parentIndex, index) {
          scope.inputs[parentIndex]['thirdPartyAudiences'].splice(index, 1);
          if (!scope.inputs[parentIndex].thirdPartyAudiences.length && !scope.inputs[parentIndex].notThirdPartyAudiences?.length) {
            scope.inputs.splice(parentIndex, 1);
          }
        };

        scope.removeNotFromRow = function (parentIndex, index) {
          scope.inputs[parentIndex]['notThirdPartyAudiences'].splice(index, 1);
          if (!scope.inputs[parentIndex].thirdPartyAudiences.length && !scope.inputs[parentIndex].notThirdPartyAudiences?.length) {
            scope.inputs.splice(parentIndex, 1);
          }
        };

        function getAudienceIds() {
          return scope.inputs
            .map(row => [row["thirdPartyAudiences"], row["notThirdPartyAudiences"]])
            .flatMap(audienceArray => audienceArray?.flatMap(audience => audience ?? []) ?? [])
            .flatMap(audience => audience?.id ?? []);
        }

        scope.simpleOutput = audience => _.get(audience, ["thirdPartyAudiences", "length"]) ? audience["thirdPartyAudiences"][0] : audience["notThirdPartyAudiences"][0];

        scope.search = function(newSearch) {
          scope.searchMeta.resolved = false;

          if (newSearch) {
            scope.searchMeta.currentPage = 1;
          }
          ThirdPartyAudience.query({pageSize: 4000}, function(page) {
            scope.list = {searchResults: _.map(page.results, function(entry) {
                const data = _.pick(entry, ['id', 'provider', 'externalReference', 'name', 'description', 'objectState']);
                const audienceIds = getAudienceIds() || [];
                if (audienceIds.indexOf(data.id) > -1) {
                  data.selected = true;
                }
                return data;
              })
            };
            scope.searchMeta.resolved = true;
          });
        };

        scope.searchMeta = {
          pageSize: 50,
          currentPage: 1,
          resolved: true
        };

        scope.hook = {};
        scope.hook.getTemplateData = function() {
          var summary = _.flatten(_.map(scope.model, function(item) {
            return _.map(item.thirdPartyAudiences, 'name');
          })).join(", ");
          return {
            vm: angular.copy(scope.model),
            model: angular.copy(scope.model),
            constant: ADN_TARGETING_TYPES.ndr,
            summary: summary
          };
        };

        const copyTemplateFunc = function(template) {
          scope.model = angular.copy(template.data.model);
        };
        scope.hook.copyTemplate = copyTemplateFunc;


        let prepareForSave = function() {
          scope.model = scope.model || [];
          scope.model.length = 0;
          _.forEach(scope.inputs, function(entry) {
            if (scope.template.complexInput) {
              scope.model.push(entry);
            } else {
              scope.model.push(entry.thirdPartyAudiences.length ? {thirdPartyAudiences: [entry.thirdPartyAudiences[0]]} : {notThirdPartyAudiences: [entry.notThirdPartyAudiences[0]]});
            }
          });
          init();
        };

        tabset.register(ADN_TARGETING_TYPES.thirdPartyAudience.widget, function() {
          if (scope.queryDone) {
            return;
          }
          scope.queryDone = false;
          scope.search();

          const audienceIds = getAudienceIds();
          ThirdPartyAudience.query(_.map(audienceIds), function (page) {
            const selAudiences = page.results;

            _.forEach(scope.model, function (model) {
              [model["thirdPartyAudiences"], model["notThirdPartyAudiences"]]
                .flatMap(audienceArray => audienceArray?.flatMap(audience => audience ?? []) ?? [])
                .forEach(audience => {
                  if (!audience || !audience.id) {
                    return;
                  }
                  let foundAudience = _.find(selAudiences, function (s) {
                    return s.id === audience.id;
                  });
                  if (foundAudience) {
                    audience.selected = true;
                    audience.className = "label-default";
                    audience.objectState = foundAudience.objectState;
                    audience.description = foundAudience.description;
                    audience.externalReference = foundAudience.externalReference;
                    audience.provider = foundAudience.provider;
                  } else {
                    audience.className = "label-danger";
                    audience.translation = "Unknown";
                    audience.objectState = "UNINDEXED";
                    audience.externalReference = "-";
                    audience.provider = 'UNKNOWN';
                    $log.warn("Couldn't find selected audience in list", audience);
                  }
                });
            });
            scope.inputs = _.cloneDeep(scope.model);
            scope.queryDone = true;
          });
        }, copyTemplateFunc);

        tabset.callBeforeSubmit(prepareForSave);
        tabset.registerAllTabs(prepareForSave);
      }
    };
  });

export default MODULE_NAME;