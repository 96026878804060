/**
 * Copyright © 2024 Adnuntius AS.
 */
import angular from 'angular';
import _ from 'lodash';

import standardFormModule from '../../common/standard-form-module';
import creativeViewDir from './creative-preview-via-creative-id-component';

const MODULE_NAME = 'creative-preview-controller';

angular.module(MODULE_NAME, [standardFormModule, creativeViewDir])

  .controller('CreativePreviewCtrl', function(livePreviewResource, lineItem, $q, Creative, LocalNetworkProfile, creative, teamId) {
    const ctrl = this;

    ctrl.$onInit = function() {
      ctrl.creative = creative;

      ctrl.isLoadingCreative = false;
      if (!creative.layoutParameters || _.isEmpty(creative.layoutParameters)) {
        ctrl.isLoadingCreative = true;
        Creative.getHydratedPreview(creative.id).then(function(c) {
          ctrl.creative = c;
          ctrl.htmlUseCreativeId = !!_.find(_.get(ctrl, ['creative', 'layoutParameters']), function(v) {
            return v.content;
          });
          const creativeInIndex = !_.find(_.get(ctrl.creative, ['validationWarnings']), function(v) {
            return v.preventsAdServing || v.code === 'validation.warning.creative.line.item.ended';
          });
          ctrl.htmlUseCreativeId = ctrl.htmlUseCreativeId && creativeInIndex;
          ctrl.isLoadingCreative = false;
        });
      }

      ctrl.displayCreativeDanger = !!_.find(creative.validationWarnings, ['preventsAdServing', true]);

      const badState = lineItem.userState !== 'RESERVED' && lineItem.userState !== 'APPROVED' && lineItem.userState !== 'PAUSED';
      const badLineItem = _.filter(lineItem.validationWarnings, function(w) {
        return w.preventsAdServing && w.code !== 'validation.warning.lineItem.unapproved';
      }).length > 0;
      ctrl.displayLineItemDanger = badState || badLineItem || lineItem.executionState === 'ENDED';

      let assetsPromise;
      ctrl.createLivePreview = function(form, viewUrl) {
        const delimiter = _.indexOf(viewUrl, "?") > 10 ? "&" : "?";
        ctrl.adnPreviewLink = `${viewUrl}${delimiter}adn-lp-li=${lineItem.id}&adn-lp-c=${creative.id}`;

        form.$setValidity('processing');
        assetsPromise = _.get(ctrl.creative, ['assets']) ? $q.when(ctrl.creative) : (assetsPromise || Creative.hydrate(ctrl.creative));
        assetsPromise.then(function(c) {
          let layoutParams = {};
          _.forEach(c.layoutParameters, function(v, k) {
            if (c.layoutParameters[k] && c.layoutParameters[k].id) {
              let assetId = c.layoutParameters[k].id;
              layoutParams[k] = _.pick((_.find(c.assets, ['id', assetId]) || {}), ['cdnId', 'previewCdnId', 'width', 'height', 'content']);
            } else {
              layoutParams[k] = v;
            }
          });

          livePreviewResource.create(
            viewUrl,
            LocalNetworkProfile.getNetworkTag(),
            teamId,
            _.get(c, ['layout', 'id']),
            layoutParams,
            creative.width,
            creative.height
          ).then(function(data) {
            form.$setValidity('processing', true);
            ctrl.livePreviewLink = data.livePreviewLink;
          });
        });
      };
    };
  });

export default MODULE_NAME;