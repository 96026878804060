export class IpAddress {
  private static numberCheck(number: number, value: string): boolean {
    return number.toString() === value && number > -1 && number < 256;
  }

  static isValid(data: string): boolean {
    const theSplit = data.split(".");
    if (theSplit.length !== 4) {
      return false;
    }

    for (let i = 0; i < theSplit.length; i++) {
      const splitNumber = theSplit[i].split("/");
      const number = parseInt(theSplit[i], 10);
      if (number.toString().indexOf(".") > -1) {
        return false;
      }
      if (i < 3 || splitNumber[0] === theSplit[i]) {
        if (!this.numberCheck(number, theSplit[i])) {
          return false;
        }
      } else {
        if (splitNumber.length !== 2) {
          return false;
        }
        if (!this.numberCheck(parseInt(splitNumber[0], 10), splitNumber[0])) {
          return false;
        }
        if (!this.numberCheck(parseInt(splitNumber[1], 10), splitNumber[1])) {
          return false;
        }
      }
    }
    return true;
  }
}
