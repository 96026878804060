/**
 * Copyright © 2024 Adnuntius AS.
 */
import angular from 'angular';
import translate from 'angular-translate';
import highchartsNg from 'highcharts-ng';

import promiseSpinnerComponent from '../directives/html/promise-spinner-component';

import template from './heatmap.html';
import Highcharts from "highcharts";
import _ from 'lodash';
import {calculateDatesAsWeeks} from "../controller/allocation-calculator";

const MODULE_NAME = "heatmap-directive";

angular.module(MODULE_NAME, [
  highchartsNg,
  translate,
  promiseSpinnerComponent
])

  .component('adnHeatmap', {
    bindings: {
      triggerRedraw: '<',
      stats: '<',
      dates: '<',
      isPending: '<',
      noDataMessage: '@'
    },
    controllerAs: 'ctrl',
    template: template,
    controller: function() {
      const ctrl = this;

      ctrl.haveStats = false;

      let createChartConfig = function() {
        const weekLabels = calculateDatesAsWeeks(ctrl.dates);
        return {
          chart: {
            type: 'heatmap',
            plotBorderWidth: 1
          },
          title: {
            text: ''
          },
          xAxis: {
            categories: ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'],
            min: 0,
            max: 6,
            gridLineWidth: 1
          },
          yAxis: {
            categories: weekLabels,
            reversed: true,
            title: null,
            min: 0,
            gridLineWidth: 1
          },
          legend: {
            align: 'right',
            layout: 'vertical',
            margin: 0,
            verticalAlign: 'top'
          },
          colorAxis: {
            min: 0,
            minColor: '#FFFFFF',
            maxColor: Highcharts.getOptions().colors[0]
          },
          credits: {
            enabled: false
          },
          exporting: {
            enabled: false
          },
          accessibility: {
            enabled: false
          },
          tooltip: {
            formatter: function() {
              return '<b>' + ctrl.dates[this.point.index] + '</b>';
            }
          },
          series: [
            {
              borderWidth: 1,
              data: ctrl.stats,
              dataLabels: {
                enabled: true,
                color: '#000000'
              }
            }
          ]
        };
      };

      ctrl.$onInit = function() {
        function startStatsGet() {
          ctrl.statsChart = createChartConfig();
          ctrl.noData = !_.isArray(ctrl.stats) || ctrl.stats.length === 0;
          ctrl.haveStats = true;
        }
        startStatsGet();

        ctrl.triggerRedraw = ctrl.triggerRedraw || {};
        ctrl.triggerRedraw.execute = function() {
          startStatsGet();
        };
      };
    }
  });

export default MODULE_NAME;