/**
 * Copyright © 2024 Adnuntius AS.
 */
import angular from 'angular';
import _ from 'lodash';
import translate from 'angular-translate';

import template from './domain-targeting.html';
import {ADN_TARGETING_TYPES} from "../targeting-constants";

const MODULE_NAME = 'domain-targeting-directive';

angular.module(MODULE_NAME, [translate])

  .directive('adnDomainTargeting', function() {
    return {
      restrict: 'A',
      require: '^^adnTabset',
      template: template,
      scope: {
        model: '=adnDomainTargeting',
        underModel: '=',
        isLockedDown: '<',
        overridableTargeting: '<',
        mode: '@'
      },
      link: {
        post: function(scope, element, attrs, tabset) {
          scope.loaded = true;
          scope.targetType = ADN_TARGETING_TYPES.domain;
          let init = function() {
            let inputted = [];
            _.forEach(_.get(scope.model, 'names'), function(addy) {
              inputted.push({val: addy});
            });
            if (inputted.length === 0) {
              inputted.push({val: ''});
            }
            scope.inputted = {value: inputted};
          };
          init();

          scope.addDomain = function() {
            scope.inputted.value = scope.inputted.value || [];
            scope.inputted.value.push({val: ""});
          };

          scope.removeDomain = function(index) {
            if (index === 0 && _.get(scope.inputted, ['value'], []).length < 2) {
              _.set(scope.inputted.value, [index, 'val'], "");
              return;
            }
            _.pullAt(scope.inputted.value, index);
          };

          let prepareForSave = function() {
            let isValidDomainName = function(data) {
              let re = /^(\*\.)?([\w\d-]+\.)*[\w\d]+$/;
              return re.test(data);
            };

            let values = _.map(scope.inputted.value, 'val');
            let trimmedValues = _.filter(_.map(values, function(v) {
              return _.trim(v);
            }), function(v) {
              return _.isString(v) && v.length > 0 && isValidDomainName(v);
            });

            scope.model = scope.model || {names: []};
            scope.model.names = trimmedValues;
            scope.model.negated = scope.model.negated || false;
            init();
          };

          scope.hook = {};
          scope.hook.getTemplateData = function() {
            prepareForSave();
            var summary = (scope.model.negated === true ? "NOT: " : "") + scope.model.names.join(", ");
            return {
              vm: angular.copy(scope.inputted),
              model: angular.copy(scope.model),
              constant: ADN_TARGETING_TYPES.domain,
              summary: summary
            };
          };

          const copyTemplateFunc = function(template) {
            scope.model = angular.copy(template.data.model);
            init();
          };
          scope.hook.copyTemplate = copyTemplateFunc;

          tabset.register(ADN_TARGETING_TYPES.domain.widget, prepareForSave, copyTemplateFunc);
          tabset.callBeforeSubmit(prepareForSave);
          tabset.registerAllTabs(prepareForSave);
        }
      }
    };
  });

export default MODULE_NAME;