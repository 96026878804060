/**
 * Copyright © 2024 Adnuntius AS.
 */
import * as moment from 'moment';
import * as _ from 'lodash';

import {ADN_TARGETING_TYPES, ALL_TARGETING_TYPES, SPECIAL_NO_CATEGORY_TARGET} from "./targeting-constants";
import {convertIabCategoryToString} from "../../components/util/iab-taxonomy";

const isComplexFunc = function (model, prop) {
  let isComplex = false;
  _.forEach(model, function (entryObj) {
    if (_.get(entryObj, [prop, 'length']) > 1 || _.trim(_.get(entryObj, [prop, 0]), "").indexOf(",") > -1 || _.get(entryObj[_.camelCase('not' + _.upperFirst(prop))], 'length') > 0) {
      isComplex = true;
      return false;
    }
    _.forEach(entryObj[prop], function (entry) {
      if (_.startsWith(entry, "NOT")) {
        isComplex = true;
        return false;
      }
    });
  });
  return isComplex;
};

const isComplexBooleanFunc = function (pModel, prop, notProp) {
  const model = _.filter(pModel, function (entryObj) {
    return _.get(entryObj, [prop, 0]) !== SPECIAL_NO_CATEGORY_TARGET && _.get(entryObj, [prop, 0]) !== "NOT " + SPECIAL_NO_CATEGORY_TARGET;
  });

  const numberOfEntries = _.filter(_.map(model, function (ks) {
    return _.get(ks, [prop], []).length + _.get(ks, [notProp], []).length;
  }), function (lengths) {
    return lengths > 0;
  }).length;

  let allMatch = numberOfEntries > 0;
  if (allMatch) {
    const andKeywordFirstEntry = _.get(model, [0, prop], []);
    const andKeywordSecondEntry = _.get(model, [1, prop], []);
    const andIntersection = _.intersection(andKeywordFirstEntry, andKeywordSecondEntry);
    allMatch = andIntersection.length === 0 ? false : _.filter(model.slice(1), function (entryObj) {
      return !_.isEqual(andIntersection, _.intersection(andKeywordFirstEntry, entryObj[prop]));
    }).length === 0;
  }
  const notsAndPipedNotsEmpty = _.filter(model, function (entryObj) {
    const hasNotWithPipe = _.filter(_.get(entryObj, [prop], []), function (val) {
      return val.indexOf("NOT") === 0 && val.indexOf("|") > -1;
    });
    return _.get(entryObj, [notProp, 'length']) > 0 || hasNotWithPipe.length > 0;
  }).length === 0;

  return (isComplexFunc(model, prop) && (!allMatch || !notsAndPipedNotsEmpty) && (numberOfEntries > 1 || !notsAndPipedNotsEmpty));
};

export class TargetingHelper {
  static isEntireDay(date) {
    return moment(date.first).startOf('day').isSame(moment(date.first)) &&
      moment(date.first).startOf('day').isSame(moment(date.second).subtract(1, 'd').startOf('day')) &&
      moment(date.second).startOf('day').isSame(moment(date.second));
  }

  static getTargetingTypeFromTemplate(targetingTemplateType) {
    return _.find(ADN_TARGETING_TYPES, function (type) {
      return type.templateType === targetingTemplateType;
    });
  }

  static deriveTargetingType(apiType) {
    return _.find(ADN_TARGETING_TYPES, function (tt) {
      return tt.apiType === apiType;
    });
  }

  static getSummary(targeting, target) {
    if (target === ADN_TARGETING_TYPES.device) {
      return _.reduce(targeting, function (summary, target) {
        _.forEach(target, function (values, prop) {
          const cv = _.union(summary[prop], values);
          if (cv.length) {
            summary[prop] = cv;
          }
        });
        return summary;
      }, {});
    }
    if (target === ADN_TARGETING_TYPES.namedLocation) {
      return _.map(targeting.locations, function (loc) {
        return loc.name;
      }).join(', ');
    }
  }

  static getTargetsWithTargets(model, targetingParams: Array<string>) {
    const targeting = _.get(model, targetingParams, {});
    return this.getTargetsWithTargetsDirectly(targeting);
  }

  static getTargetsWithTargetsDirectly(targeting) {
    const targetingIds = _.map(_.filter(ADN_TARGETING_TYPES, function (tt) {
      return TargetingHelper.getSizeOfTargetingTypes(targeting, tt.targets) > 0;
    }), 'id') || [];
    return _.pick(ADN_TARGETING_TYPES, targetingIds);
  }

  static getSizeOfTargetingTypes(targetingModel, targetType) {
    let targeting = _.cloneDeep(targetingModel);
    if (!targeting) {
      return 0;
    }
    const TYPES: any = ALL_TARGETING_TYPES;
    const matchingType: any = _.find(TYPES, function (adnType) {
      return adnType.targets === targetType;
    });
    if (!matchingType) {
      return 0;
    }
    targeting = targeting[matchingType.targets] || targeting;
    if (matchingType === TYPES.viewability) {
      return _.isFinite(targeting[TYPES.viewability.apiProp]) ? 1 : 0;
    }
    if (matchingType === TYPES.geospatial) {
      const oneTarget = targeting[0] || {};
      return (_.get(oneTarget, 'definition.geometries') || []).length;
    }
    if (matchingType.targetingLength === true) {
      if (matchingType.checkComplex && !TargetingHelper.isComplex(targeting, matchingType.id)) {
        const modelData = {model: _.cloneDeep(targetingModel)};
        return TargetingHelper.simpleInit(modelData, matchingType.apiProp);
      }
      const targetingArray = _.isArray(targeting) ? targeting : _.isArray(_.get(targeting, [targetType])) ? _.get(targeting, [targetType]) : [];
      if (targetingArray.length === 1) {
        const theClone = _.cloneDeep(targetingArray[0]);
        delete theClone['$$hashKey'];
        return _.isEmpty(theClone) ? 0 : 1;
      }
      return targetingArray.length;
    }
    if (_.isString(matchingType.targetingLength)) {
      const arrayParam = matchingType.targetingLength;
      return _.isArray(_.get(targeting, arrayParam)) || _.isArray(_.get(targeting[targetType], arrayParam)) ? (_.get(targeting, arrayParam) || []).length || (_.get(targeting[targetType], arrayParam) || []).length : 0;
    }
    const theTargets = targeting[0] || targeting;
    const theValues = theTargets ? theTargets[matchingType.apiProp] : [];
    return _.isArray(theValues) ? theValues.length : 0;
  }

  static checkAndCreateBaseInput(scope, param= 'keywords', notParam= 'notKeywords') {
    if (!scope.inputs[0] || !scope.inputs[0][param] || scope.inputs[0][param].length === 0) {
      const empty = {};
      empty[param] = [];
      empty[notParam] = [];
      scope.inputs[0] = scope.inputs[0] || empty;
      scope.inputs[0][param] = scope.inputs[0][param] || [];
      scope.inputs[0][param].push({k: '', not: false});

      scope.inputs[0][notParam] = scope.inputs[0][notParam] || [];
    }
  }

  static complexInit(scope, targetingType = ADN_TARGETING_TYPES.keyword.id, param = 'keywords', notParam = 'notKeywords') {
    scope.inputs = [];

    const setUpEntries = function (data, savedData) {
      _.forEach(data, function (k) {
        if (targetingType === ADN_TARGETING_TYPES.category.id) {
          if (k === SPECIAL_NO_CATEGORY_TARGET || k === ('NOT ' + SPECIAL_NO_CATEGORY_TARGET)) {
            return;
          }
        }
        const isNot = _.startsWith(k.toUpperCase(), "NOT ");
        savedData.push({k: isNot ? k.substr(4).split("|").join(", ") : k.split("|").join(", "), not: isNot});
      });
    };
    _.forEach(scope.model, function (entryObj) {
      const inputted = [],
        notInputted = [];
      setUpEntries(entryObj[param], inputted);
      setUpEntries(entryObj[notParam], notInputted);
      const pushObj = {};
      pushObj[param] = inputted;
      pushObj[notParam] = notInputted;
      scope.inputs.push(pushObj);
    });
    TargetingHelper.checkAndCreateBaseInput(scope, param, notParam);
  }

  static simpleInit(scope, apiProp): number {
    const inputted = [];

    const modelToCheck = _.filter(scope.model, function (entryObj) {
      return _.get(entryObj, [apiProp, 0]) !== SPECIAL_NO_CATEGORY_TARGET && _.get(entryObj, [apiProp, 0]) !== "NOT " + SPECIAL_NO_CATEGORY_TARGET;
    });

    _.forEach(modelToCheck, function (entryObj) {
      const basicValue = _.trim(_.get(entryObj, [apiProp, 0]));
      if (basicValue) {
        inputted.push(basicValue);
      }
    });

    const numberOfEntries = _.filter(_.map(modelToCheck, function (ks) {
      return _.get(ks, [apiProp, 'length'], 0);
    }), function (lengths) {
      return lengths > 0;
    }).length;

    let allMatch = numberOfEntries > 0;
    let orUniques = [];
    let orUniquesAnded = [];
    let allWithoutNots = [];
    let allNots = [];
    if (numberOfEntries === 1) {
      const theSingleEntry = modelToCheck[0];
      if (theSingleEntry[apiProp].length === 1 && _.get(theSingleEntry, [apiProp, 0]).indexOf("NOT") < 0 && theSingleEntry[apiProp][0].indexOf("|") < 0) {
        orUniques = theSingleEntry[apiProp];
      } else {
        const preTest = _.map(_.filter(theSingleEntry[apiProp], function (k: string) {
          return k.indexOf("|") > 0 && k.indexOf("NOT ") < 0;
        }), function (k: string) {
          return k.split("|");
        });
        if (preTest.length === 0) {
          orUniques = [];
        } else if (preTest.length === 1) {
          orUniques = _.flatten(preTest);
        } else {
          orUniques = preTest.shift();
          orUniquesAnded = _.cloneDeep(preTest);
        }
        allWithoutNots = _.filter(theSingleEntry[apiProp], function (k: string) {
          return k.indexOf("NOT ") !== 0 && k.indexOf("|") < 0;
        });
        allNots = _.filter(theSingleEntry[apiProp], function (k: string) {
          return k.indexOf("NOT ") === 0 && k.indexOf("|") < 0;
        });
      }
    } else if (allMatch) {
      const andKeywordFirstEntry = _.get(modelToCheck, [0, apiProp], []);
      const andKeywordSecondEntry = _.get(modelToCheck, [1, apiProp], []);
      const andIntersection = _.intersection(andKeywordFirstEntry, andKeywordSecondEntry);
      allMatch = _.filter(modelToCheck.slice(1), function (entryObj) {
        return !_.isEqual(andIntersection, _.intersection(andKeywordFirstEntry, entryObj[apiProp]));
      }).length === 0;

      if (allMatch) {
        _.forEach(modelToCheck, function (entryObj) {
          orUniques = _.union(orUniques, entryObj[apiProp]);
        });
        orUniques = _.pullAll(orUniques, andIntersection);

        allWithoutNots = _.filter(andIntersection, function (k: string) {
          return k.indexOf("NOT ") !== 0;
        });
        allNots = _.filter(andIntersection, function (k: string) {
          return k.indexOf("NOT ") === 0;
        });
      }
    }

    const valueArray = allMatch ? orUniques : inputted;
    const valueOrAndedArray = allMatch ? orUniquesAnded : [];
    const valueAndArray = allMatch ? allWithoutNots : [];
    const valueNotArray = allMatch ? allNots : [];
    TargetingHelper.assignToInput(scope, valueArray, valueAndArray, valueNotArray, inputted, valueOrAndedArray);

    const orUniqueCount = _.reduce(orUniquesAnded, function (sum, el) {
      return sum + el.length;
    }, 0);
    const runningCount = valueArray.length + valueAndArray.length + valueNotArray.length + orUniqueCount;
    return runningCount + scope.model.length - modelToCheck.length;
  }

  static assignToInput(scope, entryObjOrListString, pAndKeywords, pAndNotKeywords, pSimpleKeywords, pOrAnded) {
    const listStrings = entryObjOrListString.value ? entryObjOrListString.value || [] : entryObjOrListString;
    const listOrAndedStrings = entryObjOrListString.valueAnded ? entryObjOrListString.valueAnded || [] : pOrAnded;
    const andKeywords = entryObjOrListString.valueAll ? entryObjOrListString.valueAll || [] : pAndKeywords || [];
    const andNotKeywords = entryObjOrListString.valueNotAny ? entryObjOrListString.valueNotAny || [] : pAndNotKeywords || [];
    const simpleKeywords = entryObjOrListString.simpleValue ? entryObjOrListString.simpleValue || [] : pSimpleKeywords || [];
    const andNotKeywordsStripped = _.map(andNotKeywords, function (k) {
      return k.replace("NOT ", "");
    });
    const simpleKeywordsStripped = _.filter(simpleKeywords, function(v) {
      return v.indexOf("|") < 0;
    });
    scope.inputted = {
      value: listStrings.join(", "), valueAll: andKeywords.join(", "), valueNotAny: andNotKeywordsStripped.join(", "), simpleValue: simpleKeywordsStripped.join(", ")
    };

    _.forEach(listOrAndedStrings, function (val) {
      scope.inputted.valueAnded = scope.inputted.valueAnded || [];
      scope.inputted.valueAnded.push({value: val.join(", ")});
    });

    function iabCategorise(theStrings) {
      return _.filter(_.map(theStrings, function (v) {
        return convertIabCategoryToString(v, true);
      }), function (v) {
        return v;
      }).join(", ");
    }
    scope.inputted.valueIabs = iabCategorise(listStrings);
    scope.inputted.valueAllIabs = iabCategorise(andKeywords);
    scope.inputted.valueNotAnyIabs = iabCategorise(andNotKeywordsStripped);
    scope.inputted.simpleValueIabs = iabCategorise(simpleKeywordsStripped);
  }

  static trimValues(scope, removeValue?: string) {
    function trimVal(val) {
      const lineBreaksRemoved = val.replace(/\r\n|\r|\n/g, ",");
      const values = lineBreaksRemoved.split(",");
      return _.filter(_.map(values, function (v) {
        return _.trim(v);
      }), function (v) {
        return _.isString(v) && v.length > 0 && (!removeValue || (removeValue !== v && removeValue !== 'NOT ' + v));
      });
    }

    function trimFunc(param) {
      return trimVal(_.get(scope, ['inputted', param], ""));
    }

    function valueAnded() {
      const valueAnded = _.get(scope, ['inputted', 'valueAnded'], []);
      return _.filter(_.map(valueAnded, function (va) {
        return trimVal(va.value);
      }), function(v) {
        return v;
      }) || [];
    }
    return {value: trimFunc("value"), valueAll: trimFunc("valueAll"), valueNotAny: trimFunc("valueNotAny"), simpleValue: trimFunc("simpleValue"), valueAnded: valueAnded()};
  }

  static assignAllTheValuesToSimpleModel(scope, apiProp) {
    const allTheValues = TargetingHelper.trimValues(scope);
    _.forEach(allTheValues.simpleValue, function (v) {
      const obj = {};
      obj[apiProp] = [v];
      scope.model.push(obj);
    });
  }

  static assignAllTheValuesToPipedModel(scope, apiProp, maxLength) {
    function orReturn(orArray) {
      const orValue = orArray.join("|");
      return _.isString(orValue) && orValue.length > 0 ? [orValue] : [];
    }

    const allTheValues = TargetingHelper.trimValues(scope);
    const maxLengthedAllValues = _.cloneDeep(allTheValues);

    if (maxLength > 0) {
      maxLengthedAllValues.value = allTheValues.value.slice(0, Math.min(maxLength, allTheValues.value.length));
      const nextMax = Math.max(0, maxLength - allTheValues.value.length);
      maxLengthedAllValues.valueAll = allTheValues.valueAll.slice(0, Math.min(nextMax, allTheValues.valueAll.length));
      const nextNextMax = Math.max(0, maxLength - allTheValues.valueAll.length - allTheValues.value.length);
      maxLengthedAllValues.valueNotAny = allTheValues.valueNotAny.slice(0, Math.min(nextNextMax, allTheValues.valueNotAny.length));
    }

    const startingArray = orReturn(maxLengthedAllValues.value);
    let runningModel = startingArray.concat(maxLengthedAllValues.valueAll).concat(_.map(maxLengthedAllValues.valueNotAny, function (k) {
      return "NOT " + k;
    }));
    _.forEach(maxLengthedAllValues.valueAnded, function (vand) {
      runningModel = runningModel.concat(orReturn(vand));
    });
    if (runningModel.length > 0) {
      const obj = {};
      obj[apiProp] = runningModel;
      scope.model.push(obj);
    }
    return {
      length: allTheValues.value.length + allTheValues.valueAll.length + allTheValues.valueNotAny.length,
      maxedLength: maxLengthedAllValues.value.length + maxLengthedAllValues.valueAll.length + maxLengthedAllValues.valueNotAny.length
    };
  }

  static assignComplexValuesToNewModel(scope, param = 'keywords', notParam = 'notKeywords') {
    _.forEach(scope.inputs, function (theInput) {
      let hasAnUpdate = false;
      const formattedForSave = [],
        notFormattedForSave = [],
        loopThrough = function (param, objForSave) {
          _.forEach(theInput[param], function (entry) {
            const trimmedValues = _.filter(_.map(entry.k.split(","), function (k) {
              return _.trim(k);
            }), function (k) {
              return _.isString(k) && k.length > 0;
            });
            if (trimmedValues.length > 0) {
              const values = (entry.not ? "NOT " : "") + trimmedValues.join("|");
              objForSave = objForSave || [];
              objForSave.push(values);
              hasAnUpdate = true;
            }
          });
        };

      loopThrough(param, formattedForSave);
      loopThrough(notParam, notFormattedForSave);
      if (hasAnUpdate) {
        const saveObj = {};
        saveObj[param] = formattedForSave;
        saveObj[notParam] = notFormattedForSave;
        scope.model.push(saveObj);
      }
    });
  }

  static isComplex(model, targetConstantId) {
    if (targetConstantId === ADN_TARGETING_TYPES.keyword.id) {
      return isComplexBooleanFunc(model, 'keywords', 'notKeywords');
    }
    if (targetConstantId === ADN_TARGETING_TYPES.userSegment.id) {
      return isComplexBooleanFunc(model, 'userSegments', 'notUserSegments');
    }
    if (targetConstantId === ADN_TARGETING_TYPES.category.id) {
      return isComplexBooleanFunc(model, 'categories', 'notCategories');
    }
  }

  static isComplexSegments(model) {
    return isComplexFunc(model, 'userSegments');
  }

  static isComplexThirdPartyAudience(model) {
    return isComplexFunc(model, 'thirdPartyAudiences');
  }
}
