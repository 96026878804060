/**
 * Copyright © 2024 Adnuntius AS.
 */
import angular from 'angular';
import {OBJECT_TYPE} from "../common/constants/object-type";

import template from './line-item-copy-modal.html';

import standardActionsModule from '../common/standard-actions-module';
import searchSelect from '../common/controller/search-select-component';
import starDirective from '../common/directives/star/star-directive';
import {Uuid} from "../../components/util/uuid";

const MODULE_NAME = "line-item-actions-controller";

angular.module(MODULE_NAME, [standardActionsModule, searchSelect, starDirective])

  .controller('LineItemActionsCtrl', function($q, toastr, adnModalLoading, LineItem, $log, $state, $stateParams, $uibModal, model, modelComms, readOnly) {
    const ctrl = this;

    ctrl.objectType = OBJECT_TYPE.LineItem.type;
    ctrl.objectId = model.id;
    ctrl.readOnly = readOnly;

    ctrl.model = model;
    ctrl.modelComms = modelComms;
    ctrl.isNew = $stateParams.isNew;

    ctrl.copy = function() {
      let modalInstance = $uibModal.open({
        template: template,
        windowClass: '',
        size: 'lg',
        controllerAs: 'modalCtrl',
        controller: /*@ngInject*/ function() {
          const modalCtrl = this;

          modelComms.addSubscriber(function(data, type) {
            if (!type) {
              modalCtrl.lineItem = data;
              modalCtrl.lineItem.name = data.name + " (copy)";
              modalCtrl.lineItem.userState = 'PROPOSED';
            }
          });

          modalCtrl.destination = true;
          modalCtrl.copy = {
            targeting: true,
            creatives: true
          };

          modalCtrl.disabled = false;
          modalCtrl.validationErrors = [];
          modalCtrl.copyCampaign = function() {
            modalCtrl.disabled = true;
            adnModalLoading.applyModalLoading();
            const newId = Uuid.generate();
            $q.all(LineItem.copyLineItem(modalCtrl.lineItem, newId, modalCtrl.copy.targeting, modalCtrl.copy.creatives)).then(function() {
              modalCtrl.disabled = false;
              modalInstance.close({destination: modalCtrl.destination, lineItemId: newId});
              adnModalLoading.removeModalLoading();
            }, function(errors) {
              modalCtrl.disabled = false;
              modalCtrl.validationErrors = errors.data.errors;
              adnModalLoading.removeModalLoading();
            });
          };
        }
      });
      modalInstance.result.then(function(result) {
        adnModalLoading.removeModalLoading();
        if (result.destination) {
          $state.go('app.line-items.line-item', {id: result.lineItemId}, {inherit: false});
        }
        if (result.errors) {
          $log.warn(result.errors);
          toastr.warning("This line item could not be copied.", "Creative Copy Failed");
        } else if (!result.destination) {
          toastr.success("A copy of this line item was successfully created.", "Line Item Copied");
        }
      }, function() {
        // dismissal handler to avoid unhandled exceptions -- don't blame me, blame uibModal
      });
    };
  });

export default MODULE_NAME;