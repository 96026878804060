/**
 * Copyright © 2024 Adnuntius AS.
 */
import angular from 'angular';
import _ from 'lodash';
import uiBootstrap from 'angular-ui-bootstrap';
import translate from 'angular-translate';

import template from './site-country-targeting.html';

import resources from '../../../components/api/resources/resources';
import promiseSpinner from '../../common/directives/html/promise-spinner-component';
import searchPagination from '../../common/directives/pagination/search-pagination-component';
import {ADN_TARGETING_TYPES} from "../targeting-constants";
import {ALL_COUNTRIES} from "../../common/directives/country-select/countries";

const MODULE_NAME = 'site-country-targeting-directive';

angular.module(MODULE_NAME, [translate, uiBootstrap, resources, promiseSpinner, searchPagination])

  .directive('adnSiteCountryTargeting', function(SiteCountry) {
    return {
      restrict: 'A',
      require: '^^adnTabset',
      template: template,
      scope: {
        model: '=adnSiteCountryTargeting',
        underModel: '=',
        isLockedDown: '<',
        modelLineItem: '<',
        runningModel: '<',
        overridableTargeting: '<',
        mandatory: '<',
        mode: '@'
      },

      link: function(scope, element, attrs, tabset) {
        scope.resolved = false;
        scope.loaded = true;
        scope.targetType = ADN_TARGETING_TYPES.siteCountry;

        // we send through to the API [id, id]. API returns [{id}, {id}]. Hence the difference here.
        scope.model = scope.model || {countries: []};
        if (_.get(scope.model.countries, '[0].code')) {
          scope.model.countries = _.map(scope.model.countries, function(s) {
            return s.code;
          });
        }

        scope.selectAll = function() {
          scope.model.countries = _.map(scope.siteCountries, function(s) {
            return s.code;
          });
        };

        scope.deselectAll = function() {
          scope.model.countries = [];
        };

        scope.hook = {};
        scope.hook.getTemplateData = function() {
          var summary = (scope.model.negated === true ? "NOT: " : "") + _.map(_.filter(scope.siteCountries, function(siteCountry) {
            return scope.model.siteCountries.indexOf(siteCountry.code) > -1;
          }), 'name').join(", ");
          return {
            vm: angular.copy(scope.model),
            model: angular.copy(scope.model),
            constant: ADN_TARGETING_TYPES.siteCountry,
            summary: summary
          };
        };

        const copyTemplateFunc = function(template) {
          scope.model = angular.copy(template.data.model);
        };
        scope.hook.copyTemplate = copyTemplateFunc;

        tabset.register(ADN_TARGETING_TYPES.siteCountry.widget, function() {
          if (scope.resolved) {
            return;
          }
          SiteCountry.get().$promise.then(function(page) {
            scope.siteCountries = [];
            scope.emptySiteCount = 0;
            _.forEach(page.countries, function(country) {
              if (!country.country) {
                scope.emptySiteCount = country.count;
                return;
              }
              const countryObj = _.find(ALL_COUNTRIES, function(c) {
                return c.code === country.country;
              }) || {
                code: country.country,
                name: country.country
              };
              countryObj.count = country.count;
              scope.siteCountries.push(countryObj);
            });
            scope.resolved = true;
          });
        }, copyTemplateFunc);
      }
    };
  });

export default MODULE_NAME;