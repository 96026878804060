/**
 * Copyright © 2024 Adnuntius AS.
 */
import angular from 'angular';
import _ from 'lodash';
import translate from 'angular-translate';

const MODULE_NAME = 'site-group-target-summary-directive';

angular.module(MODULE_NAME, [translate])

  .directive('adnTargetingSiteGroupTargetSummary', function(SiteGroup) {
    return {
      restrict: 'A',
      template: '<strong ng-if="target.negated">NOT:&nbsp;</strong>{{siteGroups}}',
      scope: {
        targets: '=adnTargetingSiteGroupTargetSummary'
      },
      link: function(scope) {
        let cache = {};
        scope.$watchCollection('targets', function(targets) {
          scope.target = targets;
        });
        scope.$watchCollection('target', function(target) {

          let hasNameAlready = !!_.get(target, 'siteGroups[0].name');
          if (hasNameAlready) {
            scope.siteGroups = _.map(target.siteGroups, function(siteGroup) {
              return siteGroup.name;
            }).join(', ');
          } else {
            let siteGroupIds = _.get(target, 'siteGroups[0].id') ? _.map(target.siteGroups, 'id') : target.siteGroups,
              queryIds = _.without(siteGroupIds, _.keys(cache));
            SiteGroup.query(queryIds, function(page) {
              _.forEach(page.results, function(s) {
                cache[s.id] = s.name;
              });
              scope.siteGroups = _.map(siteGroupIds, function(id) {
                return cache[id];
              }).join(', ');
            });
          }
        });
      }
    };
  });

export default MODULE_NAME;