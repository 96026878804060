/**
 * Copyright © 2024 Adnuntius AS.
 */
import angular from 'angular';
import _ from 'lodash';
import uiBootstrap from 'angular-ui-bootstrap';
import translate from 'angular-translate';

import template from './retargeting-targeting.html';

import resources from '../../../components/api/resources/resources';
import localNetworkProfile from '../../../components/session/local-network-profile';
import promiseSpinner from '../../common/directives/html/promise-spinner-component';
import {ADN_TARGETING_TYPES} from "../targeting-constants";
import {TargetingHelper} from "../targeting-helper";
import {AdServerConfig} from "../../../components/api/api";

const MODULE_NAME = 'retargeting-directive';

angular.module(MODULE_NAME, [translate, uiBootstrap, resources, localNetworkProfile, promiseSpinner])

  .directive('adnRetargetingTargeting', function(LocalNetworkProfile) {
    return {
      restrict: 'A',
      require: '^^adnTabset',
      template: template,
      scope: {
        model: '=adnRetargetingTargeting',
        underModel: '=',
        isLockedDown: '<',
        overridableTargeting: '<',
        mandatory: '<',
        mode: '@'
      },
      link: {
        post: function(scope, element, attrs, tabset) {
          scope.loaded = true;
          scope.targetType = ADN_TARGETING_TYPES.retargeting;
          let init = function() {
            scope.inputs = [];

            let setUpEntries = function(data, savedData) {
              _.forEach(data, function(v, k) {
                let isNot = _.startsWith(k, "NOT ");
                let nextLevelV = _.isArray(v[0]) ? v : [v];
                _.forEach(nextLevelV, function(vLevel) {
                  savedData.push({k: isNot ? k.substr(3) : k, v: vLevel.join(', '), not: isNot});
                });
              });
            };
            _.forEach(scope.model, function(entryObj) {
              let inputted = [],
                notInputted = [];
              setUpEntries(entryObj.entries, inputted);
              setUpEntries(entryObj.notEntries, notInputted);
              scope.inputs.push({entries: inputted, notEntries: notInputted});
            });
            if (!scope.inputs[0] || !scope.inputs[0].entries || scope.inputs[0].entries.length === 0) {
              scope.inputs[0] = scope.inputs[0] || {entries: [], notEntries: []};
              scope.inputs[0].entries = scope.inputs[0].entries || [];
              scope.inputs[0].entries.push({k: '', v: '', not: false});

              scope.inputs[0].notEntries = scope.inputs[0].notEntries || [];
            }
          };
          init();

          scope.addEntry = function() {
            scope.inputs.push({entries: [{k: '', v: '', not: false}], notEntries: []});
          };

          scope.addRow = function(index) {
            scope.inputs[index].entries.push({k: '', v: '', not: false});
          };

          scope.addNotRow = function(index) {
            scope.inputs[index].notEntries.push({k: '', v: '', not: false});
          };

          scope.negateRow = function(parentIndex, index, isNot) {
            let param = isNot ? 'notEntries' : 'entries';
            scope.inputs[parentIndex][param][index].not = !scope.inputs[parentIndex][param][index].not;
          };

          scope.removeRow = function(parentIndex, index, isNot) {
            scope.inputs[parentIndex][isNot ? 'notEntries' : 'entries'].splice(index, 1);
            if (scope.inputs[parentIndex].entries.length === 0 && scope.inputs[parentIndex].notEntries.length === 0) {
              scope.inputs.splice(parentIndex, 1);
            }
          };

          let prepareForSave = function() {
            scope.model = scope.model || [];
            scope.model.length = 0;
            _.forEach(scope.inputs, function(theInput) {
              let formattedForSave = {},
                notFormattedForSave = {},
                hasAnUpdate = false,
                loopThrough = function(param, objForSave) {
                  _.forEach(theInput[param], function(entry) {
                    let trimKey = (entry.not ? "NOT " : "") + _.trim(entry.k),
                      trimValues = _.trim(entry.v);
                    if (_.isString(trimKey) && trimKey.length > 0 && _.isString(trimValues) && trimValues.length > 0) {
                      let values = trimValues.split(',');
                      let mappedValues = _.filter(_.map(values, function(v) {
                        return _.trim(v);
                      }), function(v) {
                        return _.isString(v) && v.length > 0;
                      });
                      objForSave[trimKey] = objForSave[trimKey] || [];
                      objForSave[trimKey].push(mappedValues);
                      hasAnUpdate = true;
                    }
                  });
                };

              loopThrough('entries', formattedForSave);
              loopThrough('notEntries', notFormattedForSave);
              if (hasAnUpdate) {
                scope.model.push({entries: formattedForSave, notEntries: notFormattedForSave});
              }
            });
            init();
          };

          scope.hook = {};
          scope.hook.getTemplateData = function() {
            prepareForSave();

            const mapEntries = _.map(_.get(scope.model, [0, 'entries']), function(val, key) {
              return {
                key: key,
                val: val
              };
            });

            const isSimple = scope.model.length === 1 && mapEntries.length === 1 && mapEntries[0].val.length === 1 && _.isEmpty(scope.model[0].notEntries);
            return {
              vm: angular.copy(scope.vm),
              model: angular.copy(scope.model),
              constant: ADN_TARGETING_TYPES.retargeting,
              summary: isSimple ? (mapEntries[0].key + ": " + mapEntries[0].val[0].join(", ")) : TargetingHelper.getSizeOfTargetingTypes(scope.model, ADN_TARGETING_TYPES.retargeting.targets) + " retargeting with logic"
            };
          };

          const copyTemplateFunc = function(template) {
            scope.model = angular.copy(template.data.model);
            init();
          };
          scope.hook.copyTemplate = copyTemplateFunc;

          tabset.register(ADN_TARGETING_TYPES.retargeting.widget, prepareForSave, copyTemplateFunc);
          tabset.callBeforeSubmit(prepareForSave);
          tabset.registerAllTabs(prepareForSave);

          let networkTag = LocalNetworkProfile.getNetworkTag();
          scope.retargetingImages = '<img src="' + AdServerConfig.obtain().getUri("pixelr.gif") + '?network=' + networkTag + '&shop=petshop.com.au&expiry=2592000">\n' +
            '<img src="' + AdServerConfig.obtain().getUri("pixelr.gif") + '?network=' + networkTag + '&age=39&expiry=5184000">\n' +
            '<img src="' + AdServerConfig.obtain().getUri("pixelr.gif") + '?network=' + networkTag + '&sport=handball">';
        }
      }
    };
  });

export default MODULE_NAME;