/**
 * Copyright © 2024 Adnuntius AS.
 */
import angular from 'angular';
import _ from 'lodash';
import uiBootstrap from 'angular-ui-bootstrap';
import translate from 'angular-translate';

import template from './keyvalue-targeting.html';

import resources from '../../../components/api/resources/resources';
import promiseSpinner from '../../common/directives/html/promise-spinner-component';
import {ADN_TARGETING_TYPES} from "../targeting-constants";
import {TargetingHelper} from "../targeting-helper";

const MODULE_NAME = 'key-value-targeting-directive';

angular.module(MODULE_NAME, [translate, uiBootstrap, resources, promiseSpinner])

  .directive('adnKeyValueTargeting', function(keyValueSearchResource) {
    return {
      restrict: 'A',
      require: '^^adnTabset',
      template: template,
      scope: {
        model: '=adnKeyValueTargeting',
        underModel: '=',
        isLockedDown: '<',
        overridableTargeting: '<',
        mandatory: '<',
        mode: '@'
      },
      link: {
        post: function(scope, element, attrs, tabset) {
          scope.loaded = true;
          scope.targetType = ADN_TARGETING_TYPES.keyValue;

          function checkAndCreateBaseInput() {
            if (!scope.inputs[0] || !scope.inputs[0].entries || scope.inputs[0].entries.length === 0) {
              scope.inputs[0] = scope.inputs[0] || {entries: [], notEntries: []};
              scope.inputs[0].entries = scope.inputs[0].entries || [];
              scope.inputs[0].entries.push({k: '', v: '', not: false});

              scope.inputs[0].notEntries = scope.inputs[0].notEntries || [];
            }
          }

          let init = function() {
            scope.inputs = [];

            let setUpEntries = function(data, savedData) {
              _.forEach(data, function(v, k) {
                let isNot = _.startsWith(k.toUpperCase(), "NOT ");
                let nextLevelV = _.isArray(v[0]) ? v : [v];
                _.forEach(nextLevelV, function(vLevel) {
                  savedData.push({k: isNot ? k.substr(4) : k, v: vLevel.join(', '), not: isNot});
                });
              });
            };
            _.forEach(scope.model, function(entryObj) {
              let inputted = [],
                notInputted = [];
              setUpEntries(entryObj.entries, inputted);
              setUpEntries(entryObj.notEntries, notInputted);
              scope.inputs.push({entries: inputted, notEntries: notInputted});
            });
            checkAndCreateBaseInput();
          };
          init();

          scope.resolved = true;
          scope.search = function(newSearch) {
            scope.searchMeta.resolved = false;

            if (newSearch) {
              scope.searchMeta.currentPage = 1;
            }

            keyValueSearchResource.query({q: scope.search.term || '', pageSize: scope.searchMeta.pageSize, page: scope.searchMeta.currentPage - 1}).then(function(page) {
              scope.list = page;
              scope.searchMeta.resolved = true;
            });
          };

          scope.searchMeta = {
            pageSize: 50,
            currentPage: 1,
            changePage: function() {
              scope.search();
            },
            resolved: true
          };

          scope.addOr = function(searchResult) {
            checkAndCreateBaseInput();
            const theIndex = scope.inputs.length - 1;
            let lastEntry = scope.inputs[theIndex].notEntries.length > 0 ? scope.inputs[theIndex].notEntries[scope.inputs[theIndex].notEntries.length - 1] : scope.inputs[theIndex].entries[scope.inputs[theIndex].entries.length - 1];

            if (!lastEntry.k || lastEntry.k === searchResult.key) {
              lastEntry.k = searchResult.key;
              lastEntry.v = searchResult.values;
            } else {
              scope.addEntry(searchResult.key, searchResult.values);
            }
          };
          scope.addItem = function(key, value) {
            checkAndCreateBaseInput();
            const theIndex = scope.inputs.length - 1;
            let lastEntry = scope.inputs[theIndex].notEntries.length > 0 ? scope.inputs[theIndex].notEntries[scope.inputs[theIndex].notEntries.length - 1] : scope.inputs[theIndex].entries[scope.inputs[theIndex].entries.length - 1];

            if (!lastEntry.k || lastEntry.k === key) {
              lastEntry.k = key;
              if (lastEntry.v) {
                lastEntry.v += ", ";
              }
              lastEntry.v += value;
            } else {
              scope.addEntry(key, value);
            }
          };


          scope.addAnd = function(searchResult) {
            scope.addRow(null, searchResult.key, searchResult.values);
          };
          scope.addAndNot = function(searchResult) {
            scope.addNotRow(null, searchResult.key, searchResult.values);
          };

          scope.addEntry = function(key, value) {
            let k = key || '';
            let v = value || '';
            scope.inputs.push({entries: [{k: k, v: v, not: false}], notEntries: []});
          };

          scope.addRow = function(index, key, value) {
            let k = key || '';
            let v = value || '';
            let theIndex = _.isFinite(index) ? index : scope.inputs.length - 1;
            scope.inputs[theIndex].entries.push({k: k, v: v, not: false});
          };

          scope.addNotRow = function(index, key, value) {
            let k = key || '';
            let v = value || '';
            let theIndex = _.isFinite(index) ? index : scope.inputs.length - 1;
            scope.inputs[theIndex].notEntries.push({k: k, v: v, not: false});
          };

          scope.negateRow = function(parentIndex, index, isNot) {
            let param = isNot ? 'notEntries' : 'entries';
            scope.inputs[parentIndex][param][index].not = !scope.inputs[parentIndex][param][index].not;
          };

          scope.removeRow = function(parentIndex, index, isNot) {
            scope.inputs[parentIndex][isNot ? 'notEntries' : 'entries'].splice(index, 1);
            if (scope.inputs[parentIndex].entries.length === 0 && scope.inputs[parentIndex].notEntries.length === 0) {
              scope.inputs.splice(parentIndex, 1);
            }
          };

          let prepareForSave = function() {
            scope.model = scope.model || [];
            scope.model.length = 0;
            _.forEach(scope.inputs, function(theInput) {
              let formattedForSave = {},
                notFormattedForSave = {},
                hasAnUpdate = false,
                loopThrough = function(param, objForSave) {
                  _.forEach(theInput[param], function(entry) {
                    let trimKey = (entry.not ? "NOT " : "") + _.trim(entry.k),
                      trimValues = _.trim(entry.v);
                    if (_.isString(trimKey) && trimKey.length > 0 && _.isString(trimValues) && trimValues.length > 0) {
                      let values = trimValues.split(',');
                      let mappedValues = _.filter(_.map(values, function(v) {
                        return _.trim(v);
                      }), function(v) {
                        return _.isString(v) && v.length > 0;
                      });
                      objForSave[trimKey] = objForSave[trimKey] || [];
                      objForSave[trimKey].push(mappedValues);
                      hasAnUpdate = true;
                    }
                  });
                };

              loopThrough('entries', formattedForSave);
              loopThrough('notEntries', notFormattedForSave);
              if (hasAnUpdate) {
                scope.model.push({entries: formattedForSave, notEntries: notFormattedForSave});
              }
            });
            init();
          };

          scope.hook = {};
          scope.hook.getTemplateData = function() {
            prepareForSave();

            const mapEntries = _.map(_.get(scope.model, [0, 'entries']), function(val, key) {
              return {
                key: key,
                val: val
              };
            });

            const isSimple = scope.model.length === 1 && mapEntries.length === 1 && mapEntries[0].val.length === 1 && _.isEmpty(scope.model[0].notEntries);
            return {
              vm: angular.copy(scope.vm),
              model: angular.copy(scope.model),
              constant: ADN_TARGETING_TYPES.keyValue,
              summary: isSimple ? (mapEntries[0].key + ": " + mapEntries[0].val[0].join(", ")) : TargetingHelper.getSizeOfTargetingTypes(scope.model, ADN_TARGETING_TYPES.keyValue.targets) + " key-values with logic"
            };
          };

          const copyTemplateFunc = function(template) {
            scope.model = angular.copy(template.data.model);
            init();
          };
          scope.hook.copyTemplate = copyTemplateFunc;

          tabset.register(ADN_TARGETING_TYPES.keyValue.widget, scope.search, copyTemplateFunc);
          tabset.callBeforeSubmit(prepareForSave);
          tabset.registerAllTabs(prepareForSave);
        }
      }
    };
  });

export default MODULE_NAME;