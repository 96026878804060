/**
 * Copyright © 2024 Adnuntius AS.
 */
import angular from 'angular';
import {OBJECT_TYPE} from "../../constants/object-type";

import template from './star-directive.html';

import starService from '../../../../components/star/star-service';

const MODULE_NAME = 'star-directive';

angular.module(MODULE_NAME, [starService])
  .component('adnStar', {
    bindings: {
      adnObject: '@',
      adnObjectType: '@'
    },
    template: template,
    controllerAs: 'ctrl',
    controller: function(StarService) {
      const ctrl = this;

      ctrl.$onInit = function() {
        ctrl.isStarred = false;

        let thisObjectType = OBJECT_TYPE[ctrl.adnObjectType];
        ctrl.isStarred = StarService.isStarred(ctrl.adnObject, thisObjectType);

        ctrl.toggleStar = function() {
          let newStarred = !ctrl.isStarred;
          StarService.updateStar(ctrl.adnObject, thisObjectType, newStarred).then(function() {
            ctrl.isStarred = newStarred;
          });
        };
      };
    }
  });

export default MODULE_NAME;