/**
 * Copyright © 2024 Adnuntius AS.
 */
import angular from 'angular';
import _ from 'lodash';
import uiBootstrap from 'angular-ui-bootstrap';
import translate from 'angular-translate';

import template from './named-location-targeting.html';

import resources from '../../../components/api/resources/resources';
import promiseSpinner from '../../common/directives/html/promise-spinner-component';
import {ADN_TARGETING_TYPES} from "../targeting-constants";
import {TargetingHelper} from "../targeting-helper";
import LocalNetworkProfile from "../../../components/session/local-network-profile";
import {getCountryEntry} from "../../common/directives/country-select/countries";

const MODULE_NAME = 'named-location-targeting-directive';

const NORWAY = {code: "NO", name: "Norway"};
const SWEDEN = {code: "SE", name: "Sweden"};
const DENMARK = {code: "DK", name: "Denmark"};

angular.module(MODULE_NAME, [translate, uiBootstrap, resources, promiseSpinner, LocalNetworkProfile])

  .directive('adnNamedLocationTargeting', function (locationResource, LocalNetworkProfile) {
    return {
      restrict: 'A',
      require: '^^adnTabset',
      template: template,
      scope: {
        model: '=adnNamedLocationTargeting',
        underModel: '=',
        isLockedDown: '<',
        modelLineItem: '<',
        overridableTargeting: '<',
        mandatory: '<',
        mode: '@'
      },
      link: function (scope, el, atts, tabset) {
        scope.model = scope.model || {};
        scope.model.locations = scope.model.locations || [];
        scope.model.negated = scope.model.negated || false;
        scope.resolved = true;
        scope.loaded = true;
        scope.targetType = ADN_TARGETING_TYPES.namedLocation;

        const lineItemId = _.get(scope.modelLineItem, ['id'], undefined);
        scope.restriction = {value: ''};
        scope.showOptions = [];
        if (lineItemId) {
          const productName = _.get(scope.modelLineItem, ['axProduct', 'name']) || "";
          if (productName.indexOf("Norway") > -1 || productName.indexOf("Norge") > -1) {
            scope.showOptions.push(NORWAY);
          }
          if (productName.indexOf("Sweden") > -1 || productName.indexOf("Sverige") > -1) {
            scope.showOptions.push(SWEDEN);
          }
          if (productName.indexOf("Denmark") > -1 || productName.indexOf("Danmark") > -1) {
            scope.showOptions.push(DENMARK);
          }
        }
        if (!scope.showOptions.length) {
          const siteCountries = _.get(scope.modelLineItem, ['targeting', ADN_TARGETING_TYPES.siteCountry.targets, ADN_TARGETING_TYPES.siteCountry.apiProp]) || [];
          if (siteCountries.length > 0) {
            if (siteCountries.indexOf("NO") > -1) {
              scope.showOptions.push(NORWAY);
            }
            if (siteCountries.indexOf("SE") > -1) {
              scope.showOptions.push(SWEDEN);
            }
            if (siteCountries.indexOf("DK") > -1) {
              scope.showOptions.push(DENMARK);
            }
          }
        }
        const defaults = LocalNetworkProfile.getDefaults();
        if (_.get(defaults, ['namedLocations'], []).length) {
          _.forEach(defaults.namedLocations, function(loc) {
            const countryEntry = getCountryEntry(loc);
            if (countryEntry) {
              scope.showOptions.push(countryEntry);
            }
          });
        }
        if (scope.showOptions.length > 0) {
          scope.restriction.value = scope.showOptions[0].code;
        }

        let doSearch = function (newSearch) {
          scope.searchMeta.resolved = false;

          if (newSearch) {
            scope.searchMeta.currentPage = 1;
          }

          const queryParams = {q: scope.search.term || '', types: scope.filterBucket, pageSize: scope.searchMeta.pageSize, page: scope.searchMeta.currentPage - 1};
          if (scope.restriction.value) {
            queryParams.countryId = scope.restriction.value;
          }
          return locationResource.query(queryParams).then(function(page) {
            scope.aggregations = _.pick(page.aggregations, 'types');
            scope.list = page;

            scope.searchResults = {
              items: page.searchResults,
              totalHits: page.totalHits,
              pageStart: page.pageStart,
              pageEnd: page.pageEnd
            };
            _.forEach(scope.searchResults.items, function(l) {
              l.isSelected = !!_.find(scope.model.locations, function(targetedL) {
                return l.id === targetedL.id;
              });
            });
            scope.searchMeta.resolved = true;
          });
        };

        scope.search = function() {
          scope.filterBucket = undefined;
          doSearch(true);
        };

        scope.searchMeta = {
          pageSize: 50,
          currentPage: 1,
          changePage: function() {
            doSearch();
          },
          resolved: true
        };

        scope.treeMeta = {
          pageSize: 200,
          currentPage: 1,
          showChildren: function(result) {
            result.showChildren = !result.showChildren;
            getChildren(result);
          },
          changePage: function(result) {
            getChildren(result);
          },
          resolved: true
        };

        scope.clearSearch = function() {
          scope.search.term = '';
          scope.filterBucket = undefined;
          scope.searchResults = undefined;
          scope.aggregations = undefined;
        };

        scope.filterOnBucket = function(bucketName) {
          scope.filterBucket = bucketName;
          doSearch(true);
        };

        scope.addToTarget = function(location) {
          scope.model.locations.push(location);
          location.isSelected = true;
        };

        scope.removeFromTarget = function(location) {
          scope.model.locations = _.filter(scope.model.locations, function(l) {
            return l.id !== location.id;
          });
          _.forEach(_.get(scope, ['treeResults', 'items']) || [], function(item) {
            if (item.id === location.id) {
              item.isSelected = false;
            }
          });
          _.forEach(_.get(scope, ['treeChildren']) || [], function(treeChild) {
            _.forEach(_.get(treeChild, ['items']) || [], function(subItem) {
              if (subItem.id === location.id) {
                subItem.isSelected = false;
              }
            });
          });
          _.forEach(_.get(scope, ['searchResults', 'items']) || [], function(item) {
            if (item.id === location.id) {
              item.isSelected = false;
            }
          });
          location.isSelected = false;
        };

        scope.removeAllFromTarget = function() {
          scope.model.locations = [];
          _.forEach(_.get(scope, ['treeResults', 'items']) || [], function(item) {
            item.isSelected = false;
          });
          _.forEach(_.get(scope, ['treeChildren', 'items']) || [], function(item) {
            item.isSelected = false;
          });
          _.forEach(_.get(scope, ['treeChildren']) || [], function(treeChild) {
            _.forEach(_.get(treeChild, ['items']) || [], function(subItem) {
              subItem.isSelected = false;
            });
          });
          _.forEach(_.get(scope, ['searchResults', 'items']) || [], function(item) {
            item.isSelected = false;
          });
        };

        scope.treeChildren = {};

        const getChildren = function(result) {
          result.currentPage = result.currentPage || scope.treeMeta.currentPage;
          const childId = result.id + '-' + result.currentPage;
          if (!result.showChildren || _.get(scope.treeChildren[childId], 'items')) {
            return;
          }
          locationResource.query({parent: result.id, pageSize: scope.treeMeta.pageSize, blankOrderBy: true, page: result.currentPage - 1}).then(function(page) {
            scope.treeChildren[childId] = {
              items: page.searchResults,
              totalHits: page.totalHits,
              pageStart: page.pageStart,
              pageEnd: page.pageEnd,
              type: _.get(page, ['searchResults', 0, 'type'], '')
            };
            _.forEach(scope.treeChildren[childId].items, function(l) {
              l.context = result.name + (result.context ? (", " + result.context) : "");
              l.ips = result.ips;
              l.isSelected = !!_.find(scope.model.locations, function(targetedL) {
                return l.id === targetedL.id;
              });
            });
            scope.treeChildren[childId].resolved = true;
            scope.treeChildren[childId].pageResolved = true;
          });
        };

        scope.hook = {};
        scope.hook.getTemplateData = function() {
          prepareForSave();
          const summaryData = TargetingHelper.getSummary(scope.model, ADN_TARGETING_TYPES.namedLocation);
          const summaryString = (scope.model.negated ? "NOT: " : "") + summaryData;

          return {
            model: angular.copy(scope.model),
            constant: ADN_TARGETING_TYPES.namedLocation,
            summary: summaryString
          };
        };
        const copyTemplateFunc = function(template) {
          scope.model = angular.copy(template.data.model);
        };
        scope.hook.copyTemplate = copyTemplateFunc;

        tabset.register(ADN_TARGETING_TYPES.namedLocation.widget, function() {
          if (scope.query) {
            return;
          }
          locationResource.query({q: '', types: 'CONTINENT', blankOrderBy: true, pageSize: scope.treeMeta.pageSize, page: scope.treeMeta.currentPage - 1}).then(function(page) {
            scope.treeResults = {
              items: page.searchResults,
              totalHits: page.totalHits,
              pageStart: page.pageStart,
              pageEnd: page.pageEnd
            };
            _.forEach(scope.treeResults.items, function(l) {
              l.isSelected = !!_.find(scope.model.locations, function(targetedL) {
                return l.id === targetedL.id;
              });
            });
            scope.treeMeta.resolved = true;
          });
        }, copyTemplateFunc);

        let prepareForSave = function() {
          _.forEach(scope.model.locations, function(l) {
            delete l.isSelected;
          });
        };

        tabset.callBeforeSubmit(prepareForSave);
      }
    };
  });

export default MODULE_NAME;