/**
 * Copyright © 2024 Adnuntius AS.
 */
import angular from 'angular';
import _ from 'lodash';
import translate from 'angular-translate';

const MODULE_NAME = 'adunit-target-summary-directive';

angular.module(MODULE_NAME, [translate])

  .directive('adnTargetingAdUnitSummary', function() {
    return {
      restrict: 'A',
      template: `
        <div>
            <strong ng-if="target.negated">NOT:&nbsp;</strong>
            <span>{{adUnits}}</span>
            <div ng-if="restricted && isLimited"><a ng-click="restrictionLifted(true)"><span class="fa fa-chevron-down"></span> Show all</a></div>
        </div>`,
      scope: {
        targets: '=adnTargetingAdUnitSummary'
      },
      link: function(scope) {
        scope.quantity = 10;
        scope.restricted = true;

        scope.restrictionLifted = function(restrictionLifted) {
          if (restrictionLifted) {
            scope.restricted = false;
          }
          scope.adUnits = scope.restricted ? scope.data.slice(0, scope.quantity).join(", ") : scope.data.join(", ");
          scope.isLimited = scope.data.length > scope.quantity;
        };

        scope.$watchCollection('targets', function(targets) {
          scope.target = targets;
        });
        scope.$watchCollection('target', function(target) {
          scope.data = _.map(target.adUnits, function(au) {
            return au.name;
          });
          scope.restrictionLifted();
        });
      }
    };
  });

export default MODULE_NAME;