/**
 * Copyright © 2024 Adnuntius AS.
 */
import angular from 'angular';
import _ from 'lodash';

const MODULE_NAME = 'tabset-directive';

angular.module(MODULE_NAME, [])

  .directive('adnTabset', function() {
    return {
      restrict: 'A',
      scope: {
        tabController: '<adnTabset',
        onAction: '<'
      },
      controller: function($scope, $q) {
        const tabs = {};
        const beforeSubmits = [];
        const allTabSelects = [];

        let topRegistered = false;

        function execOnAction() {
          if (_.isFunction($scope.onAction)) {
            $scope.onAction();
          }
        }

        this.register = function(key, select, copyFunc) {
          tabs[key] = {
            reg: select,
            copy: (copyFunc || _.noop)
          };

          if (!topRegistered) {
            tabs[key].reg();
            topRegistered = true;
          }
        };

        this.registerAllTabs = function(func) {
          allTabSelects.push(func);
        };

        this.callBeforeSubmit = function(func) {
          beforeSubmits.push(func);
        };

        $scope.tabController.copyTargetingTemplate = function(key, template) {
          (_.get(tabs, [key, 'copy']) || _.noop)(template);
        };

        $scope.tabController.onTabSelect = function(key) {
          _.forEach(allTabSelects, function(func) {
            func();
          });
          (_.get(tabs, [key, 'reg']) || _.noop)();
          execOnAction();
        };

        $scope.tabController.tabBeforeSubmit = function() {
          const allFuncs = [];
          _.forEach(beforeSubmits, function(func) {
            allFuncs.push(func());
          });
          return $q.all(allFuncs).then(function() {
            execOnAction();
          });
        };
      }
    };
  });

export default MODULE_NAME;