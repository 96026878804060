/**
 * Copyright © 2024 Adnuntius AS.
 */
import angular from 'angular';
import translate from 'angular-translate';

const MODULE_NAME = 'viewability-targeting-summary-directive';

angular.module(MODULE_NAME, [translate])

  .directive('adnTargetingViewabilitySummary', function() {
    return {
      restrict: 'A',
      template: '<span>{{data}}%</span>',
      scope: {
        targets: '=adnTargetingViewabilitySummary'
      },
      link: function(scope) {
        scope.data = [];
        scope.$watchCollection('targets', function(targets) {
          scope.target = targets || {};
        });
        scope.$watchCollection('target', function(target) {
          scope.data = target.viewability;
        });
      }
    };
  });

export default MODULE_NAME;