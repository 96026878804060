/**
 * Copyright © 2024 Adnuntius AS.
 */
import angular from 'angular';
import _ from 'lodash';
import translate from 'angular-translate';

import template from './viewability-targeting.html';
import {ADN_TARGETING_TYPES} from "../targeting-constants";

const MODULE_NAME = 'viewability-targeting-directive';

angular.module(MODULE_NAME, [translate])

  .directive('adnViewabilityTargeting', function() {
    return {
      restrict: 'A',
      require: '^^adnTabset',
      template: template,
      scope: {
        model: '=adnViewabilityTargeting',
        isLockedDown: '<',
        overridableTargeting: '<',
        mandatory: '<',
        mode: '@'
      },
      link: {
        post: function(scope, element, attrs, tabset) {
          scope.loaded = true;
          scope.targetType = ADN_TARGETING_TYPES.viewability;

          let prepareForSave = function() {
            if (!_.isFinite(scope.model.viewability) || scope.model.viewability <= 0 || scope.model.viewability > 100) {
              scope.model.viewability = null;
            }
          };

          prepareForSave();
          tabset.register(ADN_TARGETING_TYPES.viewability.widget, prepareForSave);
          tabset.callBeforeSubmit(prepareForSave);
          tabset.registerAllTabs(prepareForSave);
        }
      }
    };
  });

export default MODULE_NAME;