/**
 * Copyright © 2024 Adnuntius AS.
 */
import angular from 'angular';
import _ from 'lodash';
import translate from 'angular-translate';

import template from './category-summary.html';
import {ADN_TARGETING_TYPES, SPECIAL_NO_CATEGORY_TARGET} from "../targeting-constants";
import {TargetingHelper} from "../targeting-helper";
import {convertIabCategoryToString} from "../../../components/util/iab-taxonomy";

const MODULE_NAME = 'category-targeting-summary-directive';

angular.module(MODULE_NAME, [translate])

  .directive('adnTargetingCategorySummary', function() {
    return {
      restrict: 'A',
      template: template,
      scope: {
        targets: '=adnTargetingCategorySummary'
      },
      link: function(scope) {
        scope.data = [];

        scope.showOnly = 2;
        scope.quantity = scope.showOnly;
        scope.switchUp = function() {
          if (scope.quantity === scope.showOnly) {
            scope.quantity = scope.data.length;
            return;
          }
          scope.quantity = scope.showOnly;
        };

        scope.$watchCollection('targets', function(targets) {
          scope.targets = targets || {};
          scope.isComplex = TargetingHelper.isComplex(scope.targets, ADN_TARGETING_TYPES.category.id);

          if (scope.isComplex) {
            scope.data = [];
            _.forEach(targets, function(entryObj) {
              const inputted = [],
                notInputted = [];
              _.forEach(entryObj.categories, function(k) {
                let vk = k;
                let isNot = _.startsWith(vk.toUpperCase(), "NOT ");
                if (vk === SPECIAL_NO_CATEGORY_TARGET) {
                  vk = "Front Page";
                }
                if (vk === ("NOT " + SPECIAL_NO_CATEGORY_TARGET)) {
                  vk = "NOT Front Page";
                  isNot = false;
                }
                inputted.push({k: convertIabCategoryToString(isNot ? vk.substr(4).split("|").join(", ") : vk.split("|").join(", ")), not: isNot});
              });
              _.forEach(entryObj.notCategories, function(k) {
                const isNot = _.startsWith(k.toUpperCase(), "NOT ");
                notInputted.push({k: convertIabCategoryToString(isNot ? k.substr(4).split("|").join(", ") : k.split("|").join(", ")), not: isNot});
              });
              scope.data.push({categories: inputted, notCategories: notInputted});
            });
          } else {
            const modelData = {model: targets};
            TargetingHelper.simpleInit(modelData, "categories");
            scope.data = _.cloneDeep(modelData.inputted);

            const value = scope.data.value.split(", ");
            const valueAll = scope.data.valueAll.split(", ");
            const valueNotAny = scope.data.valueNotAny.split(", ");

            const maxEntries = 30;
            scope.restricted = false;
            if ((value.length + valueAll.length + valueNotAny.length) > maxEntries) {
              const sliceNumberValue = Math.min(value.length, maxEntries);
              scope.data.shortValue = value.slice(0, sliceNumberValue).join(", ");

              const sliceNumberValueAll = Math.max(Math.min(valueAll.length, maxEntries - sliceNumberValue), 0);
              scope.data.shortValueAll = valueAll.slice(0, sliceNumberValueAll).join(", ");

              const sliceNumberValueNotAny = Math.max(Math.min(valueNotAny.length, maxEntries - (sliceNumberValueAll + sliceNumberValue)), 0);
              scope.data.shortValueNotAny = valueNotAny.slice(0, sliceNumberValueNotAny).join(", ");

              scope.restricted = true;
            }

            _.forEach(targets, function(t) {
              _.forEach(t.categories, function(c) {
                if (c === SPECIAL_NO_CATEGORY_TARGET) {
                  scope.data.frontPage = "Front Page";
                }
                if (c === ("NOT " + SPECIAL_NO_CATEGORY_TARGET)) {
                  scope.data.frontPage = "NOT Front Page";
                }
              });
            });
          }
        });
      }
    };
  });

export default MODULE_NAME;