/**
 * Copyright © 2024 Adnuntius AS.
 */
import angular from 'angular';
import _ from 'lodash';
import translate from 'angular-translate';
import highchartsNg from 'highcharts-ng';
import moment from 'moment';

import chartOptionsDirective from '../directives/chart-options/chart-options-directive';
import promiseSpinnerComponent from '../directives/html/promise-spinner-component';

import template from './spark.html';

const MODULE_NAME = "spark-chart-directive";

angular.module(MODULE_NAME, [
  highchartsNg,
  translate,
  chartOptionsDirective,
  promiseSpinnerComponent
])

  .directive('adnSparkChart', function($filter) {
    return {
      restrict: 'A',
      scope: {
        stats: '<adnSparkChart'
      },
      template: template,
      link: {
        // using preLink to fill out chart config BEFORE the highchart directive is compiled
        pre: function preLink(scope) {
          scope.haveStats = false;
          let theSeries = [];

          let createChartConfig = function(height) {
            return {
              chart: {
                type: 'line',
                zoomType: 'x',
                showAxes: true,
                height: height
              },
              plotOptions: {
                series: {
                  marker: {
                    enabled: true
                  },
                  turboThreshold: 2000
                }
              },
              tooltip: {
                shared: true,
                formatter: function() {
                  return this.points.reduce(function(s, point) {
                    return s + "<br><span style='color:" + point.color + "'>\u25CF</span> " + point.series.name + ": " + point.y;
                  }, "<b>" + $filter('date')(this.x, 'medium') + "</b>");
                }
              },
              yAxis: {
                title: {text: null},
                min: 0,
                allowDecimals: false,
                id: 0
              },
              xAxis: {
                type: 'datetime',
                title: {
                  enabled: false,
                  text: 'Date and Time'
                }
              },
              legend: {
                enabled: false
              },
              credits: {
                enabled: false
              },
              accessibility: {
                enabled: false
              },
              title: {
                text: null
              },
              series: theSeries
            };
          };

          scope.chartHeight = 100;
          scope.statsChart = createChartConfig(scope.chartHeight);

          let startStatsGet = function() {
            scope.statsChart.series = [{
              name: 'Upper CPM',
              id: 'upperCpm'
            }, {
              name: 'Lower CPM',
              id: 'lowerCpm'
            }];

            const eitherPass = [];
            _.forEach(scope.statsChart.series, function(series) {
              let prev = 0;
              let prevTime = 0;
              series.data = _.map(scope.stats, function(stat, index) {
                const currentValue = Math.round(stat[series.id].amount * 10000) / 10000;
                const currentTime = stat.time;
                const passFilter = Math.abs(prev - currentValue) > 0.5 && (prevTime === 0 || moment(prevTime).diff(moment(currentTime), 'minutes') > 30);
                const returnValue = {
                  x: moment(currentTime).valueOf(),
                  y: currentValue,
                  passFilter: passFilter
                };
                if (passFilter) {
                  eitherPass.push(index);
                  prev = currentValue;
                  prevTime = currentTime;
                }
                return returnValue;
              });
            });
            _.forEach(scope.statsChart.series, function(series) {
              series.data = _.filter(series.data, function(stat, index) {
                return eitherPass.indexOf(index) > -1;
              });
            });
            scope.haveStats = true;
          };
          startStatsGet();
          _.set(scope.eventHook, 'redraw', function() {
            startStatsGet();
          });
        },
        post: function postLink(scope, element) {
          element.addClass('chart');
        }
      }
    };
  });

export default MODULE_NAME;