/**
 * Copyright © 2024 Adnuntius AS.
 */
import angular from 'angular';
import _ from 'lodash';
import uiBootstrap from 'angular-ui-bootstrap';
import translate from 'angular-translate';
import checklistModel from 'checklist-model';

import template from './ad-unit-matching-label-targeting.html';

import resources from '../../../components/api/resources/resources';
import promiseSpinner from '../../common/directives/html/promise-spinner-component';
import {ADN_TARGETING_TYPES} from "../targeting-constants";

const MODULE_NAME = 'ad-unit-matching-label-targeting-directive';

angular.module(MODULE_NAME, [translate, uiBootstrap, checklistModel, resources, promiseSpinner])

  .directive('adnAdUnitMatchingLabelTargeting', function(searchResource) {
    return {
      restrict: 'A',
      require: '^^adnTabset',
      template: template,
      scope: {
        model: '=adnAdUnitMatchingLabelTargeting',
        underModel: '=',
        isLockedDown: '<',
        overridableTargeting: '<',
        mandatory: '<',
        mode: '@'
      },
      link: function(scope, element, attrs, tabset) {
        scope.resolved = false;
        scope.targetType = ADN_TARGETING_TYPES.adUnitMatchingLabel;

        let init = function() {
          let inputted = [];
          _.forEach(scope.model, function(entryObj) {
            inputted.push(entryObj.matchingLabels[0]);
          });
          scope.inputted = inputted;
        };
        init();

        let prepareForSave = function() {
          scope.model = scope.model || [];
          scope.model.length = 0;
          _.forEach(scope.inputted, function(v) {
            scope.model.push({matchingLabels: [v]});
          });
          init();
        };

        scope.remove = function(value) {
          _.remove(scope.inputted, function(input) {
            return input === value;
          });
        };

        scope.search = function(newSearch) {
          scope.searchMeta.resolved = false;

          if (newSearch) {
            scope.searchMeta.currentPage = 1;
          }

          searchResource.query({q: scope.search.term || '', types: 'MatchingLabel', objectState: 'ACTIVE', pageSize: scope.searchMeta.pageSize, page: scope.searchMeta.currentPage - 1}).then(function(page) {
            scope.list = page;
            scope.searchMeta.resolved = true;
          });
        };

        scope.searchMeta = {
          pageSize: 50,
          currentPage: 1,
          changePage: function() {
            scope.search();
          },
          resolved: true
        };

        scope.hook = {};
        scope.hook.getTemplateData = function() {
          prepareForSave();
          var summary = _.flatten(_.map(scope.model, function(item) {
            return item.matchingLabels;
          })).join(", ");
          return {
            vm: angular.copy(scope.inputted),
            model: angular.copy(scope.model),
            constant: ADN_TARGETING_TYPES.adUnitMatchingLabel,
            summary: summary
          };
        };

        const copyTemplateFunc = function(template) {
          scope.model = angular.copy(template.data.model);
          init();
        };
        scope.hook.copyTemplate = copyTemplateFunc;

        tabset.callBeforeSubmit(prepareForSave);
        tabset.registerAllTabs(prepareForSave);
        tabset.register(ADN_TARGETING_TYPES.adUnitMatchingLabel.widget, function() {
          if (scope.list) {
            return;
          }
          scope.search(true);
        }, copyTemplateFunc);
      }
    };
  });

export default MODULE_NAME;