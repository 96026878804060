/**
 * Copyright © 2024 Adnuntius AS.
 */
import {AdServerConfig} from "../../components/api/api";

const SCRIPT_LINK = "\x3Cscript src=\"https://cdn.adnuntius.com/adn.js\" async>\x3C/script>\n" +
  "\x3Cscript>\nwindow.adn = window.adn || {}; adn.calls = adn.calls || []; adn.calls.push(function() { \n  adn.regConversion({\n    eventType: 'customEventIdReplace', \n    network: 'networkIdIdReplace', \n    adSource: 'lineItemIdReplace'\n  });\n});\n\x3C/script>";
const CPA_LINK = "<img src=\"" + AdServerConfig.obtain().getUri("pixelc.gif") +
  "?eventType=customEventIdReplace&network=networkIdIdReplace&adSource=lineItemIdReplace\">";

export class CpaDefaults {

  static getLink(customEventId, tagId, modelId): string {
    return CPA_LINK
      .replace("customEventIdReplace", (customEventId || 'unspecified'))
      .replace("networkIdIdReplace", (tagId || "unspecified"))
      .replace("lineItemIdReplace", (modelId || "unspecified"));
  }

  static getScript(customEventId, tagId, modelId): string {
    return SCRIPT_LINK
      .replace("customEventIdReplace", (customEventId || 'unspecified'))
      .replace("networkIdIdReplace", (tagId || "unspecified"))
      .replace("lineItemIdReplace", (modelId || "unspecified"));
  }
}
