/**
 * Copyright © 2024 Adnuntius AS.
 */
import angular from 'angular';
import _ from 'lodash';
import moment from 'moment';
import uiSelect from 'ui-select';
import uiBootstrap from 'angular-ui-bootstrap';
import {ADN_TARGETING_TYPES} from "./targeting-constants";

import resources from '../../components/api/resources/resources';
import {TargetingHelper} from "./targeting-helper";
import {LocalNetworkInfo} from "../../components/session/local-network-info";

const MODULE_NAME = 'targeting-controller';

angular.module(MODULE_NAME, [resources, uiSelect, uiBootstrap])

  .controller('TargetingCtrl', function($scope, $q, $timeout, $uibModalInstance, pageType, reachEnabled, overridableTargeting, LocalNetworkProfile, targetingMode, TargetingTemplate, mandatoryTargets, availableTargets, adnListHelper, targeting, modelLineItem, isLockedDown, searchResource, LineItem, LocalUserPermissions) {
    $scope.targeting = angular.copy(targeting);
    $scope.targeting = $scope.targeting || {};
    _.forEach(ADN_TARGETING_TYPES, function(tt) {
      $scope.targeting[tt.targets] = $scope.targeting[tt.targets] || (tt.targetingLength ? [] : {});
    });

    $scope.overridableTargeting = overridableTargeting ? _.cloneDeep(overridableTargeting) : null;
    $scope.modelLineItem = modelLineItem;

    $scope.targetingMode = targetingMode || 'NORMAL';
    $scope.reachEnabled = reachEnabled;
    $scope.showReach = {enabled: !!$scope.reachEnabled};
    $scope.pageType = pageType;

    $scope.isLockedDown = isLockedDown;
    $scope.isSelfServiceOwn = LocalUserPermissions.isSelfServiceOwn();

    $scope.targets = availableTargets ? availableTargets : _.omit(angular.copy(ADN_TARGETING_TYPES), ['publisher', 'semantic']);
    if (LocalNetworkProfile.isPureMarketplacePlatform() && $scope.targets.adUnit && $scope.targets.site) {
      const filtered = _.filter(_.map(ADN_TARGETING_TYPES, function(val, key) {
        return key;
      }), function(field) {
        return field !== "site" && field !== "adUnit" && field !== "publisher" && $scope.targets[field];
      });
      const reorderedTypes = _.filter(['publisher', 'site', 'adUnit', 'semantic'], function(key) {
        return !availableTargets || $scope.targets[key];
      });
      $scope.targets = _.pick(ADN_TARGETING_TYPES, reorderedTypes.concat(filtered));
    }
    if (!LocalNetworkInfo.obtain().showSiteCountryTargeting()) {
      delete $scope.targets.siteCountry;
    }
    if (Object.keys(LocalNetworkProfile.getAudienceFees()).length === 0 || pageType !== 'lineItem') {
      delete $scope.targets.thirdPartyAudience;
    }

    _.forEach($scope.targets, function(t) {
      if (t.params && Object.prototype.hasOwnProperty.call(t.params, "lineItemStartDate") && modelLineItem && modelLineItem.startDate) {
        t.params.lineItemStartDate = moment(modelLineItem.startDate).toISOString();
      }
      if (t.params && Object.prototype.hasOwnProperty.call(t.params, "advertiserReference") && modelLineItem && modelLineItem.advertiserReference) {
        t.params.advertiserReference = modelLineItem.advertiserReference;
      }
      t.params = t.params || {};
      t.params.mandatory = !!_.get(mandatoryTargets, [t.id]);
    });

    $scope.mandatoryTargets = _.filter($scope.targets, function(t) {
      return _.get(t, ['params', 'mandatory']);
    }).map(function(t) {
      return t.id;
    });

    $scope.callbackHook = {
      onReachButtonClicked: function() {
        return $scope.tabController.tabBeforeSubmit();
      }
    };

    $scope.targetingSizes = {};
    $scope.calcSizes = function() {
      _.forEach(ADN_TARGETING_TYPES, function(val) {
        $scope.targetingSizes[val.targets] = TargetingHelper.getSizeOfTargetingTypes($scope.targeting[val.targets], val.targets);
      });
    };

    $scope.copyViewInvoked = function() {
      if (!$scope.targetingTemplateList) {
        $scope.targetingTemplateList = {};
        adnListHelper.setUpBasicList($scope.targetingTemplateList, TargetingTemplate, 'targetingTargetingTemplates', {filterBy: 'type', filterByLike: 'TARGETING'});
      } else {
        $scope.targetingTemplateList.makeQuery();
      }
    };

    $scope.deleteTemplateInvoked = function(template) {
      template.$delete();
      $timeout(function() {
        $scope.targetingTemplateList.makeQuery();
      }, 150);
    };

    $scope.copyTemplateInvoked = function(nt) {
      $scope.tabController.copyTargetingTemplate(TargetingHelper.getTargetingTypeFromTemplate(nt.type).widget, nt);
      nt.copy = true;
    };

    $scope.searchLineItems = function(searchElement) {
      searchResource.query({"q": searchElement.search, "types": "LineItem"}).then(function(data) {
        $scope.lineItemResults = data.searchResults;
      });
    };

    $scope.lineItemSelected = function() {
      $scope.pending = true;
      $scope.initialised = true;
      if (_.isFunction($scope.copyHook.clear)) {
        $scope.copyHook.clear();
      }
      LineItem.get({id: $scope.lineItem.id}, function(results) {
        $scope.liTargeting = results.targeting;
        $scope.pending = false;
      });
    };

    $scope.view = 'SELECTIONS';
    $scope.resetObj = {reset: false};

    $scope.tabController = {};

    $scope.liTargeting = null;
    $scope.lineItem = {};
    $scope.copyHook = {
      copy: function(target) {
        $scope.targeting[target.targets] = angular.copy($scope.liTargeting[target.targets]);
        $scope.copyHook.results(target, true);
      }
    };

    $scope.saveAndClose = function() {
      $q.when($scope.tabController.tabBeforeSubmit()).then(function() {
        $uibModalInstance.close({wc: $scope.underModel || $scope.targeting, overrides: $scope.overridableTargeting, reset: $scope.resetObj.reset});
      });
    };
  });

export default MODULE_NAME;