/**
 * Copyright © 2024 Adnuntius AS.
 */
import angular from 'angular';
import _ from 'lodash';
import translate from 'angular-translate';
import 'angular-simple-logger';
import 'ui-leaflet';

import template from './geospatial-targeting.html';

import localNetworkProfile from '../../../components/session/local-network-profile';
import promiseSpinner from '../../common/directives/html/promise-spinner-component';
import {ADN_TARGETING_TYPES} from "../targeting-constants";

const MODULE_NAME = "geospatial-targeting-directive";

angular.module(MODULE_NAME, [
  translate,
  'nemLogging',
  'ui-leaflet',
  localNetworkProfile,
  promiseSpinner
])

  .directive('adnGeospatialTargeting', function($timeout, $window, leafletData, LocalNetworkProfile) {
    return {
      restrict: 'A',
      require: '^^adnTabset',
      template: template,
      scope: {
        model: '=adnGeospatialTargeting',
        underModel: '=',
        mandatory: '<',
        overridableTargeting: '<',
        isLockedDown: '<',
        mode: '@'
      },
      link: function(scope, el, atts, tabset) {
        let gLayers,
          gMap,
          initMap = _.noop;

        scope.model = scope.model || [];
        scope.layerData = [];
        scope.layerInitialised = false;
        scope.targetType = ADN_TARGETING_TYPES.geospatial;

        scope.clearEntries = function() {
          scope.model = scope.model || [];
          scope.model.length = 0;
          scope.layerData = [];
          scope.layerData.length = 0;
          gLayers.clearLayers();
        };

        let networkDefaults = LocalNetworkProfile.getDefaults();
        scope.centralLocation = {
          lat: networkDefaults.geoLat,
          lng: networkDefaults.geoLng,
          zoom: networkDefaults.geoZoom
        };

        scope.centerOnLayer = function(layer) {
          gLayers.getLayers().forEach(function(l) {
            if (l._leaflet_id === layer.id) {
              gMap.panTo(l.getBounds().getCenter());
              return false;
            }
          });
        };

        leafletData.getMap().then(function(map) {
          let editableLayers = new $window.L.FeatureGroup(),
            colorSet = ['blue', '#333', 'brown', 'purple', '#666', 'cyan'],
            colorIndex = -1,
            currentColor = colorSet[colorIndex],
            drawControl,
            updateColor = function() {
              colorIndex++;
              colorIndex = colorIndex % colorSet.length;
              currentColor = colorSet[colorIndex];
              drawControl.setDrawingOptions({
                polygon: {
                  shapeOptions: {
                    color: currentColor
                  }
                },
                rectangle: {
                  shapeOptions: {
                    color: currentColor
                  }
                }
              });
            },
            getLayerData = function(l) {
              const rawArea = $window.L.GeometryUtil.geodesicArea(l.getLatLngs()[0]);
              const displayArea = rawArea / 1000000;
              const decimalPoints = displayArea < 10 ? 3 : 0;
              return {id: l._leaflet_id, area: rawArea, areaView: displayArea, decimalPoints: decimalPoints, color: l.options.color, geoJson: l.toGeoJSON()};
            };

          gLayers = editableLayers;
          gMap = map;
          map.addLayer(editableLayers);
          map.zoomControl.setPosition('topright');

          drawControl = new $window.L.Control.Draw({
            position: 'topright',
            draw: {
              polyline: false,
              circle: false,
              marker: false,
              circlemarker: false,
              polygon: {
                allowIntersection: false,
                drawError: {
                  color: 'red',
                  timeout: 2000
                },
                showArea: true
              },
              rectangle: {
                showArea: true
              }
            },
            edit: {
              featureGroup: editableLayers,
              edit: true,
              remove: true
            }
          });
          map.addControl(drawControl);

          initMap = function() {
            _.forEach(_.get(scope.model[0], 'definition.geometries') || [], function(geo) {
              let newVar = {type: 'FeatureCollection', features: [{type: 'Feature', properties: {}, geometry: {type: 'GeometryCollection', geometries: [geo]}}]};
              $window.L.geoJson(newVar, {
                onEachFeature: function(feature, layer) {
                  layer.getLayers().forEach(function(l) {
                    updateColor();
                    l.setStyle({color: currentColor});
                    editableLayers.addLayer(l);
                    scope.layerData.push(getLayerData(l));
                  });
                }
              });
            });
            scope.layerInitialised = true;
          };
          initMap();

          let largestLayer;
          _.forEach(scope.layerData, function(l) {
            if (!largestLayer || largestLayer.area < l.area) {
              largestLayer = l;
            }
          });
          if (largestLayer) {
            const mapLayer = _.find(gLayers.getLayers(), function(l) {
              return l._leaflet_id === largestLayer.id;
            });
            if (mapLayer) {
              gMap.panTo(mapLayer.getBounds().getCenter());
            }
            if (largestLayer.area > 300000000) {
              gMap.setZoom(4);
            } else if (largestLayer.area < 10000) {
              gMap.setZoom(15);
            }
          }
          updateColor();

          map.on($window.L.Draw.Event.CREATED, function(e) {
            let layer = e.layer;
            editableLayers.addLayer(layer);
            scope.layerData.push(getLayerData(layer));
            updateColor();
          });

          map.on($window.L.Draw.Event.DELETED, function(e) {
            e.layers.eachLayer(function(dl) {
              _.remove(scope.layerData, function(ml) {
                return ml.id === dl._leaflet_id;
              });
            });
          });

          map.on($window.L.Draw.Event.EDITED, function(e) {
            e.layers.eachLayer(function(dl) {
              let modelElementIndex = _.findIndex(scope.layerData, function(ml) {
                return ml.id === dl._leaflet_id;
              });
              if (modelElementIndex > -1) {
                scope.layerData[modelElementIndex] = getLayerData(dl);
              }
            });
          });
        });

        let prepareForSave = function(isBeforeSubmit) {
          scope.model = scope.model || [];
          if ((!isBeforeSubmit && scope.layerData.length < 1) || !scope.layerInitialised) {
            return;
          }

          scope.model.length = 0;
          let definition = {geometries: [], type: 'GeometryCollection'};
          _.forEach(scope.layerData, function(layer) {
            let geometry = {type: 'Polygon', coordinates: layer.geoJson.geometry.coordinates};
            definition.geometries.push(geometry);
          });
          scope.model.push({definition: definition});
        };

        scope.hook = {};
        scope.hook.getTemplateData = function() {
          prepareForSave();
          return {
            vm: angular.copy(scope.vm),
            model: angular.copy(scope.model),
            constant: ADN_TARGETING_TYPES.geospatial
          };
        };

        const copyTemplateFunc = function(template) {
          scope.clearEntries();
          scope.model = angular.copy(template.data.model);
          initMap();
        };
        scope.hook.copyTemplate = copyTemplateFunc;


        let beforeSubmit = function() {
          prepareForSave(true);
        };

        tabset.callBeforeSubmit(beforeSubmit);
        tabset.registerAllTabs(prepareForSave);

        tabset.register(ADN_TARGETING_TYPES.geospatial.widget, function() {
          $timeout(function() {
            scope.sayWhen = true;
          }, 500);
        }, copyTemplateFunc);
      }
    };
  });

export default MODULE_NAME;